import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axios } from 'startup';
import { notification } from 'antd';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { setCookie } from 'utils/cookies';

function TelegramAuthPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [authStatus, setAuthStatus] = useState<
        'pending' | 'authenticated' | 'error'
    >('pending');

    useEffect(() => {
        // Extract the Telegram data from URL parameters
        const urlParams = new URLSearchParams(window.location.search);
        const telegramData: Record<string, string> = {};

        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of urlParams) {
            telegramData[key] = value;
        }
        // Check if username exists
        if (!telegramData.username) {
            setAuthStatus('error');
            notification.error({
                message: t('general.ERROR'),
                description: t('login.USERNAME_REQUIRED'),
            });
            return;
        }

        // Send the Telegram data to the server
        axios
            .post('/accounts/telegram/auth', telegramData)
            .then(response => {
                if (response.data.message === 'Successfully authenticated') {
                    setCookie('accessToken', response.data.token, 30);
                    setAuthStatus('authenticated');

                    navigate('/');
                }
            })
            .catch((error: any) => {
                // Handle authentication error
                notification.error({
                    message: t('general.ERROR'),
                    description: t('login.LOGIN_ERROR'),
                });
            });
    }, []);
    return (
        <div className='login-page'>
            <div className='login-page__banner'>
                <div className='login-page__banner__gradient' />
                <div className='login-page__title'>
                    {authStatus === 'pending' && (
                        <p>{t('login.WAITING_FOR_TELEGRAM_AUTH')}</p>
                    )}
                    {authStatus === 'authenticated' && (
                        <p>{t('login.AUTHENTICATED_SUCCESSFULLY')}</p>
                    )}
                    {authStatus === 'error' && (
                        <p>{t('login.AUTHENTICATION_FAILED')}</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default TelegramAuthPage;
