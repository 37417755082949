import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Badge } from 'components/Badge';
import { ScrollArea } from 'components/ScrollArea';
import Loading from 'components/Loading';
import { useWallet } from 'providers/WalletProvider';
import { useSettings } from 'providers/SettingProvider';
import useFilteredWallets from 'hooks/useFilteredWallets';
import { formatCost } from 'utils/debank';
import { chains, EXPLORER_ENDPOINT, nativeTokens } from 'chains';
import { usePortfolioData } from 'hooks/usePortfolioData';
import { PortfolioChart } from './chart';
import Tabs from './tabs';
import './styles.scss';

function MyPortfolio() {
    const [dateRange, setDateRange] = useState('24H');

    const { wallet } = useWallet();
    const { settings } = useSettings();
    const myWallets = useFilteredWallets(wallet.myWallet);
    const {
        loading,
        totalBalance,
        totalBalanceRate,
        chainBalance,
        walletTokens,
    } = usePortfolioData(
        myWallets,
        settings.Chains,
        settings.MinimumAmount,
        settings.BlockedTokens,
        dateRange,
    );

    return loading ? (
        <Loading />
    ) : (
        <div className='myportfolio'>
            <div className='myportfolio__wrapper'>
                <div className='myportfolio__wrapper__header'>
                    <h2 className='myportfolio__wrapper__header__title'>
                        My Portfolio
                    </h2>
                    <Tabs selected={dateRange} setSelected={setDateRange} />
                </div>

                <div className='myportfolio__wrapper__total'>
                    <div>{`Total Balance: $${formatCost(totalBalance)}`}</div>
                    <Badge
                        className={`${
                            totalBalanceRate.includes('-')
                                ? 'negative-profit'
                                : 'positive-profit'
                        } myportfolio__wrapper__total__change`}
                    >
                        {totalBalanceRate}
                    </Badge>
                </div>

                <div className='myportfolio__wrapper__content'>
                    <PortfolioChart dateRange={dateRange} />

                    <ScrollArea className='myportfolio__wrapper__content__tokens'>
                        {chains
                            .sort(
                                (a, b) =>
                                    chainBalance[b.id] - chainBalance[a.id],
                            )
                            .map(
                                chain =>
                                    walletTokens[chain.id] !== undefined && (
                                        <div
                                            key={chain.id}
                                            className='scan__tokens__container'
                                        >
                                            <div className='scan__tokens__chain'>
                                                {chain.name}
                                            </div>
                                            {Object.values(
                                                walletTokens[chain.id],
                                            )
                                                .filter(
                                                    (item: any) =>
                                                        !settings.BlockedTokens.includes(
                                                            item.id,
                                                        ),
                                                )
                                                .sort(
                                                    (a: any, b: any) =>
                                                        b.price * b.amount -
                                                        a.price * a.amount,
                                                )
                                                .map((item: any) =>
                                                    nativeTokens.includes(
                                                        item.id.toString(),
                                                    ) ? (
                                                        <button
                                                            key={item.id}
                                                            type='button'
                                                            className='myportfolio__wrapper__content__tokens__token'
                                                        >
                                                            <div className='myportfolio__wrapper__content__tokens__token__info'>
                                                                <img
                                                                    className='myportfolio__wrapper__content__tokens__token__info__logo'
                                                                    src={
                                                                        item.logo_url
                                                                    }
                                                                    width={36}
                                                                    height={36}
                                                                    alt='token'
                                                                />
                                                                <div>
                                                                    {item.name}
                                                                </div>
                                                            </div>
                                                            <div className='myportfolio__wrapper__content__tokens__token__balance'>
                                                                <div>
                                                                    {`${formatCost(
                                                                        item.amount,
                                                                    )} ${
                                                                        item.symbol
                                                                    } / $${formatCost(
                                                                        item.amount *
                                                                            item.price,
                                                                    )}`}
                                                                </div>
                                                                <Badge
                                                                    className={`${
                                                                        item.rate.includes(
                                                                            '-',
                                                                        )
                                                                            ? 'negative-profit'
                                                                            : 'positive-profit'
                                                                    } myportfolio__wrapper__content__tokens__token__change`}
                                                                >
                                                                    {item.rate}
                                                                </Badge>
                                                            </div>
                                                        </button>
                                                    ) : (
                                                        <Link
                                                            key={item.id}
                                                            to={`${
                                                                EXPLORER_ENDPOINT[
                                                                    chain.id
                                                                ]
                                                            }token/${item.id}`}
                                                            className='myportfolio__wrapper__content__tokens__token'
                                                            target='_blank'
                                                            rel='noreferrer'
                                                        >
                                                            <div className='myportfolio__wrapper__content__tokens__token__info'>
                                                                <img
                                                                    className='myportfolio__wrapper__content__tokens__token__info__logo'
                                                                    src={
                                                                        item.logo_url
                                                                    }
                                                                    width={36}
                                                                    height={36}
                                                                    alt='token'
                                                                />
                                                                <div>
                                                                    {item.name}
                                                                </div>
                                                            </div>
                                                            <div className='myportfolio__wrapper__content__tokens__token__balance'>
                                                                <div>
                                                                    {`${formatCost(
                                                                        item.amount,
                                                                    )} ${
                                                                        item.symbol
                                                                    } / $${formatCost(
                                                                        item.amount *
                                                                            item.price,
                                                                    )}`}
                                                                </div>
                                                                <Badge
                                                                    className={`${
                                                                        item.rate.includes(
                                                                            '-',
                                                                        )
                                                                            ? 'negative-profit'
                                                                            : 'positive-profit'
                                                                    } myportfolio__wrapper__content__tokens__token__change`}
                                                                >
                                                                    {item.rate}
                                                                </Badge>
                                                            </div>
                                                        </Link>
                                                    ),
                                                )}
                                        </div>
                                    ),
                            )}
                    </ScrollArea>
                </div>
            </div>
        </div>
    );
}

export default MyPortfolio;
