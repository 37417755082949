import React from 'react';
import {
    ArrowDownIcon,
    ArrowUpIcon,
    CaretSortIcon,
    EyeNoneIcon,
} from '@radix-ui/react-icons';
import { Column } from '@tanstack/react-table';
import classNames from 'classnames';

import { Button } from '../Button';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '../DropDown-Menu';

interface DataTableColumnHeaderProps<TData, TValue>
    extends React.HTMLAttributes<HTMLDivElement> {
    column: Column<TData, TValue>;
    title: string;
}

export function DataTableColumnHeader<TData, TValue>({
    column,
    title,
    className,
}: DataTableColumnHeaderProps<TData, TValue>) {
    if (!column.getCanSort()) {
        return <div className={classNames(className)}>{title}</div>;
    }

    return (
        <div className={classNames('data-table-column-header', className)}>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button
                        variant='ghost'
                        size='sm'
                        className='data-table-column-header-button'
                    >
                        <span>{title}</span>
                        {
                            // eslint-disable-next-line no-nested-ternary
                            column.getIsSorted() === 'desc' ? (
                                <ArrowDownIcon className='data-table-column-header-button__icon' />
                            ) : column.getIsSorted() === 'asc' ? (
                                <ArrowUpIcon className='data-table-column-header-button__icon' />
                            ) : (
                                <CaretSortIcon className='data-table-column-header-button__icon' />
                            )
                        }
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align='start'>
                    <DropdownMenuItem
                        onClick={() => column.toggleSorting(false)}
                    >
                        <ArrowUpIcon className='data-table-column-content-icon' />
                        Asc
                    </DropdownMenuItem>
                    <DropdownMenuItem
                        onClick={() => column.toggleSorting(true)}
                    >
                        <ArrowDownIcon className='data-table-column-content-icon' />
                        Desc
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                        onClick={() => column.toggleVisibility(false)}
                    >
                        <EyeNoneIcon className='data-table-column-content-icon' />
                        Hide
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    );
}
