import React, { KeyboardEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { Search } from 'lucide-react';

import { Badge } from 'components/Badge';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { Separator } from 'components/Separator';
import { ScrollArea } from 'components/ScrollArea';
import Loading from 'components/Loading';
import { useWallet } from 'providers/WalletProvider';
import { useSettings } from 'providers/SettingProvider';
import { usePnlData } from 'hooks/usePnlData';
import { formatAddress, formatCost, verifyAddress } from 'utils/debank';
import { EXPLORER_ENDPOINT, nativeTokens } from 'chains';
import { PnlChart } from './chart';
import Tabs from './tabs';
import './styles.scss';

function Pnl() {
    const [dateRange, setDateRange] = useState('24H');
    const [selectedAddress, setSelectedAddress] = useState('');
    const [inputAddress, setInputAddress] = useState('');
    const { settings } = useSettings();

    const { wallet } = useWallet();
    const { loading, profit, profitRate, walletTokens } = usePnlData(
        selectedAddress,
        dateRange,
    );

    const handleKeyDown = async (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            if (verifyAddress(inputAddress)) {
                setSelectedAddress(inputAddress);
            }
        }
    };

    return loading ? (
        <Loading />
    ) : (
        <div className='mypnl'>
            <div className='mypnl__wrapper'>
                <div className='mypnl__wrapper__header'>
                    <h2 className='mypnl__wrapper__header__title'>
                        Proft and Loss
                    </h2>
                    <Tabs selected={dateRange} setSelected={setDateRange} />
                </div>

                <Separator />

                <div className='mypnl__wrapper__content'>
                    <ScrollArea className='mypnl__wrapper__content__wallets'>
                        <div className='mypnl__wrapper__content__wallets__wrapper'>
                            <div className='mypnl__wrapper__content__wallets__wrapper__address-input'>
                                <Search className='mypnl__wrapper__content__wallets__wrapper__address-input__icon' />
                                <Input
                                    placeholder='Input Wallet Address'
                                    className='mypnl__wrapper__content__wallets__wrapper__address-input__input'
                                    value={inputAddress}
                                    onChange={e =>
                                        setInputAddress(e.target.value)
                                    }
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                            <div className='mypnl__wrapper__content__wallets__wrapper__list'>
                                <h3 className='mypnl__wrapper__content__wallets__wrapper__list__header'>
                                    My Wallets
                                </h3>
                                {wallet.myWallet.length === 0 && (
                                    <div className='mypnl__wrapper__content__wallets__wrapper__list__wallet'>
                                        No wallets
                                    </div>
                                )}
                                {wallet.myWallet.map(one => (
                                    <Button
                                        key={one.Wallet}
                                        className={`${
                                            selectedAddress === one.Wallet
                                                ? 'mypnl__wrapper__content__wallets__wrapper__list__wallet__selected'
                                                : 'mypnl__wrapper__content__wallets__wrapper__list__wallet'
                                        }`}
                                        variant='ghost'
                                        onClick={() =>
                                            setSelectedAddress(one.Wallet)
                                        }
                                    >
                                        <div className='mypnl__wrapper__content__wallets__wrapper__list__wallet__name'>
                                            {one.Username &&
                                            one.Username.length > 10
                                                ? `${one.Username.slice(
                                                      0,
                                                      10,
                                                  )}...`
                                                : one.Username}
                                        </div>
                                        <div className='mypnl__wrapper__content__wallets__wrapper__list__wallet__address'>
                                            {formatAddress(one.Wallet)}
                                        </div>
                                    </Button>
                                ))}
                            </div>

                            <div className='mypnl__wrapper__content__wallets__wrapper__list'>
                                <h3 className='mypnl__wrapper__content__wallets__wrapper__list__header'>
                                    Stalked Wallets
                                </h3>
                                {wallet.stalkedWallet.length === 0 && (
                                    <div className='mypnl__wrapper__content__wallets__wrapper__list__wallet'>
                                        No wallets
                                    </div>
                                )}
                                {wallet.stalkedWallet.map(one => (
                                    <Button
                                        key={one.Wallet}
                                        className={`${
                                            selectedAddress === one.Wallet
                                                ? 'mypnl__wrapper__content__wallets__wrapper__list__wallet__selected'
                                                : 'mypnl__wrapper__content__wallets__wrapper__list__wallet'
                                        }`}
                                        variant='ghost'
                                        onClick={() =>
                                            setSelectedAddress(one.Wallet)
                                        }
                                    >
                                        <div className='mypnl__wrapper__content__wallets__wrapper__list__wallet__name'>
                                            {one.Username}
                                        </div>
                                        <div className='mypnl__wrapper__content__wallets__wrapper__list__wallet__address'>
                                            {formatAddress(one.Wallet)}
                                        </div>
                                    </Button>
                                ))}
                            </div>
                        </div>
                    </ScrollArea>

                    <div className='mypnl__wrapper__content__main'>
                        <div className='mypnl__wrapper__content__main__total'>
                            {profit < 0 ? (
                                <>
                                    <div>{`Profit: -$${formatCost(
                                        -profit,
                                    )}`}</div>
                                    <Badge className='negative-profit mypnl__wrapper__content__main__total__change'>
                                        {profitRate}
                                    </Badge>
                                </>
                            ) : (
                                <>
                                    <div>{`Profit: $${formatCost(
                                        profit,
                                    )}`}</div>
                                    <Badge className='positive-profit mypnl__wrapper__content__main__total__change'>
                                        {profitRate}
                                    </Badge>
                                </>
                            )}
                        </div>

                        <PnlChart
                            dateRange={dateRange}
                            selectedAddress={selectedAddress}
                        />

                        <div className='mypnl__wrapper__content__tokens'>
                            <div className='mypnl__wrapper__content__tokens__performer'>
                                <div className='mypnl__wrapper__content__tokens__performer__wrapper'>
                                    {`Best Performer: ${
                                        walletTokens[0]?.name || ''
                                    }`}
                                    {walletTokens[0]?.rate < 0 ? (
                                        <Badge className='negative-profit mypnl__wrapper__content__tokens__token__change'>
                                            {`${walletTokens[0]?.rate || 0}%`}
                                        </Badge>
                                    ) : (
                                        <Badge className='positive-profit mypnl__wrapper__content__tokens__token__change'>
                                            {`+${walletTokens[0]?.rate || 0}%`}
                                        </Badge>
                                    )}
                                </div>
                                <div className='mypnl__wrapper__content__tokens__performer__wrapper'>
                                    {`Worst Performer: ${
                                        walletTokens[walletTokens.length - 1]
                                            ?.name || ''
                                    }`}
                                    {walletTokens[walletTokens.length - 1]
                                        ?.rate < 0 ? (
                                        <Badge className='negative-profit mypnl__wrapper__content__tokens__token__change'>
                                            {`${
                                                walletTokens[
                                                    walletTokens.length - 1
                                                ]?.rate || 0
                                            }%`}
                                        </Badge>
                                    ) : (
                                        <Badge className='positive-profit mypnl__wrapper__content__tokens__token__change'>
                                            {`+${
                                                walletTokens[
                                                    walletTokens.length - 1
                                                ]?.rate || 0
                                            }%`}
                                        </Badge>
                                    )}
                                </div>
                            </div>

                            <ScrollArea className='mypnl__wrapper__content__tokens__wrapper'>
                                {walletTokens.map(token =>
                                    nativeTokens.includes(token.id) ? (
                                        <button
                                            key={token.id}
                                            type='button'
                                            className='mypnl__wrapper__content__tokens__token'
                                        >
                                            <div className='mypnl__wrapper__content__tokens__token__info'>
                                                <img
                                                    className='mypnl__wrapper__content__tokens__token__info__logo'
                                                    src={token.logo_url}
                                                    width={36}
                                                    height={36}
                                                    alt='token'
                                                />
                                                <div>{token.name}</div>
                                            </div>
                                            <div className='mypnl__wrapper__content__tokens__token__balance'>
                                                <div>{token?.amount}</div>
                                                {token.rate < 0 ? (
                                                    <Badge className='negative-profit mypnl__wrapper__content__tokens__token__change'>
                                                        {`${token.rate}%`}
                                                    </Badge>
                                                ) : (
                                                    <Badge className='positive-profit mypnl__wrapper__content__tokens__token__change'>
                                                        {`+${token.rate}%`}
                                                    </Badge>
                                                )}
                                            </div>
                                        </button>
                                    ) : (
                                        <Link
                                            key={token.id}
                                            to={`${
                                                EXPLORER_ENDPOINT[
                                                    token.chain
                                                ] ?? 'https://etherscan.io/'
                                            }token/${token.id}`}
                                            className='mypnl__wrapper__content__tokens__token'
                                            target='_blank'
                                            rel='noreferrer'
                                        >
                                            <div className='mypnl__wrapper__content__tokens__token__info'>
                                                <img
                                                    className='mypnl__wrapper__content__tokens__token__info__logo'
                                                    src={token.logo_url}
                                                    width={36}
                                                    height={36}
                                                    alt='token'
                                                />
                                                <div>{token.name}</div>
                                            </div>
                                            <div className='mypnl__wrapper__content__tokens__token__balance'>
                                                <div>{token?.amount}</div>
                                                {token.rate < 0 ? (
                                                    <Badge className='negative-profit mypnl__wrapper__content__tokens__token__change'>
                                                        {`${token.rate}%`}
                                                    </Badge>
                                                ) : (
                                                    <Badge className='positive-profit mypnl__wrapper__content__tokens__token__change'>
                                                        {`+${token.rate}%`}
                                                    </Badge>
                                                )}
                                            </div>
                                        </Link>
                                    ),
                                )}
                            </ScrollArea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Pnl;
