export const convertDateToShort = (date: string | Date) => {
    return new Date(date).toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
    });
};

export const convertDateToHour = (date: string | Date) => {
    return new Date(date).toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    });
};

export const getDateFromDateTime = (date: string | Date) => {
    return new Date(date).toISOString().split('T')[0];
};

export const getDateTime = (date: string | Date) => {
    // Create a new Date object to represent the current date and time
    const now = new Date(date);

    // Get the current year
    const year = now.getFullYear();

    // Get the current month (0-indexed, so January is 0)
    const month = now.getMonth() + 1; // Adding 1 to adjust for 0-indexing

    // Get the current day of the month
    const day = now.getDate();

    // Get the current hours in 24-hour format
    const hours = now.getHours();

    // Get the current minutes
    const minutes = now.getMinutes();

    // Round down the minutes to the nearest 60 (1 hour)
    const roundedMinutes = minutes - (minutes % 60);

    // Create a new Date object with the rounded minutes
    const roundedDate = new Date(year, month - 1, day, hours, roundedMinutes);

    return roundedDate;
};
