import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { eraseCookie, getCookie } from 'utils/cookies';
import { useSettings } from 'providers/SettingProvider';
import { useAccount } from 'providers/AccountProvider';
import { RequireAuthProps } from './interface';

function RequiresAuth({ children }: RequireAuthProps) {
    const { account, fetchAccount } = useAccount();
    const { fetchSettings } = useSettings();
    const navigate = useNavigate();

    useEffect(() => {
        if (account) return;

        if (!getCookie('accessToken')) {
            navigate('/');
            return;
        }

        fetchAccount!(success => {
            if (success) return;

            eraseCookie('accessToken');
            navigate('/');
        });
    }, []);

    useEffect(() => {
        if (account?.account?.AccountId)
            fetchSettings!(() => {}, account.account.AccountId);
    }, [account]);

    if (!account) return null;

    return children;
}

export default RequiresAuth;
