import React from 'react';
import { Modal } from 'antd';
import config from 'config';
import { Button } from 'components/Button';
import { TelegramLoginModalProps } from './interface';
import './styles.scss';

function TelegramLoginModal({ open, setOpen, login }: TelegramLoginModalProps) {
    const [otp, setOtp] = React.useState('');
    return (
        <Modal
            title='Login with telegram'
            open={open}
            onCancel={() => {
                setOpen(false);
            }}
            footer={null}
            className='telegram-login'
            centered
            width={100}
        >
            <div className='telegram-login__content'>
                <div className='telegram-login__content__information'>
                    If you have a telegram linked to your account you will be
                    logged in, otherwise a new account will be created.
                </div>
                <p className='telegram-login__content__label'>Telegram OTP *</p>
                <input
                    name='otp'
                    type='text'
                    className='telegram-login__content__otp'
                    value={otp}
                    onChange={ev => setOtp(ev.target.value)}
                />
                <Button
                    className='telgram-login__content__login'
                    onClick={() => login(otp)}
                >
                    LOGIN
                </Button>
                {/* <button
                    type='button'
                    className='telegram-login__content__login'
                    onClick={() => login(otp)}
                >
                    LOGIN
                </button> */}
                <br />
                <p className='telegram-login__content__label'>
                    How to request OTP?
                </p>
                <div style={{ textAlign: 'center' }}>
                    <a
                        href={`https://t.me/${config.BOT_NAME}`}
                        target='_blank'
                        rel='noreferrer'
                    >
                        <button
                            type='button'
                            className='telegram-login__content__request'
                        >
                            REQUEST OTP
                        </button>
                    </a>
                </div>
                <br />
                <p className='telegram-login__content__label'>Or (Manually) </p>
                <p className='telegram-login__content__instructions'>
                    1. Open Telegram and search for{' '}
                    <a
                        href={`https://t.me/${config.BOT_NAME}`}
                        target='_blank'
                        rel='noreferrer'
                    >
                        {`@${config.BOT_NAME}`}
                    </a>{' '}
                    <br />
                    2. Click on Start <br />
                    3. Type /otp and send <br />
                    4. Copy the OTP and paste it in the above field <br />
                    5. Click on Login
                </p>
            </div>
        </Modal>
    );
}

export default TelegramLoginModal;
