import { notification } from 'antd';
import Axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { getCookie } from 'utils/cookies';
import appConfig from './config';

// export const axios = Axios.create({
//     baseURL: appConfig.API_URL,
//     adapter: cache.adapter,
//     headers: {
//         'Content-Type': 'application/json',
//     },
// });

export const cachedAxios = setupCache(
    // axios instance
    Axios.create({
        baseURL: appConfig.API_URL,
        headers: {
            'Content-Type': 'application/json',
        },
    }),
);

export const axios = Axios.create({
    baseURL: appConfig.API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

function configureStartup() {
    // ANT DESIGN SECTION

    notification.config({
        placement: 'bottomRight',
        bottom: 20,
        duration: 7,
    });

    // i18n SECTION
    i18next
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next) // bind react-i18next to the instance
        .init({
            backend: {
                // for all available options read the backend's repository readme file
                loadPath: '/locales/{{lng}}.json',
            },
            fallbackLng: 'en',
            debug: appConfig.isDevelopment,

            // react i18next special options (optional)
            // override if needed - omit if ok with defaults
            /*
            react: {
              bindI18n: 'languageChanged',
              bindI18nStore: '',
              transEmptyNodeValue: '',
              transSupportBasicHtmlNodes: true,
              transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
              useSuspense: true,
            }
            */
        });

    [axios, cachedAxios].forEach(e =>
        e.interceptors.request.use(
            async config => {
                const newConfig = config;
                const token = getCookie('accessToken');
                if (token) {
                    newConfig.headers!.Authorization = token;
                }
                return newConfig;
            },
            error => Promise.reject(error),
        ),
    );

    const handleResponse = (response: any) => response;

    const handleError = async (error: any) => {
        if (error.response.status === 500) {
            notification.error({
                message: i18next.t('general.ERROR') as string,
                description: i18next.t(`errors.0`) as string,
            });
        } else if (error.response && error.response.data) {
            const dataObj =
                error.response.data instanceof Blob
                    ? JSON.parse(await error.response.data.text())
                    : error.response.data;
            if (dataObj.error) {
                notification.error({
                    message: i18next.t('general.ERROR') as string,
                    description: i18next.t(`errors.${dataObj.error}`) as string,
                });
            }
        }

        return Promise.reject(error);
    };

    [axios, cachedAxios].forEach(e => {
        e.interceptors.response.use(handleResponse, handleError);
    });
}

export default configureStartup;
