import React from 'react';

import './styles.scss';

function Loading() {
    return (
        <div id='loading-page'>
            <div id='loading-container'>
                <div id='ring' />
                <div id='ring' />
                <div id='ring' />
                <div id='ring' />
                <div id='h3'>loading</div>
            </div>
        </div>
    );
}

export default Loading;
