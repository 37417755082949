import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import classNames from 'classnames';

import './styles.scss';

const badgeVariants = cva('badge', {
    variants: {
        variant: {
            default: 'badge__default',
            secondary: 'badge__secondary',
            destructive: 'badge__destructive',
            outline: 'badge__outline',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export interface BadgeProps
    extends React.HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
    return (
        <div
            className={classNames(badgeVariants({ variant }), className)}
            {...props}
        />
    );
}

export { Badge, badgeVariants };
