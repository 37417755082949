export const menuMotion = {
    container: {
        animated: {
            opacity: [0, 1],
            transition: {
                type: 'spring',
                stiffness: 100,
                duration: 1,
            },
        },
    },
    logo: {
        rest: { x: -500, opacity: 0 },
        animated: {
            x: 0,
            opacity: 1,
            transition: {
                type: 'spring',
                duration: 1,
                ease: 'easeIn',
            },
        },
    },
    items: {
        animated: {
            y: [-100, 0, -10, 0],
            transition: {
                type: 'spring',
                stiffness: 100,
                duration: 2,
            },
        },
    },
    profile: {
        rest: { x: 500, opacity: 0 },
        animated: {
            x: 0,
            opacity: 1,
            transition: {
                type: 'spring',
                duration: 1,
                ease: 'easeIn',
            },
        },
    },
};
