import React, { KeyboardEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Search } from 'lucide-react';

import { Input } from 'components/Input';
import { ScrollArea } from 'components/ScrollArea';
import { formatAddress, formatCost } from 'utils/debank';
import { getAccountsByWallet } from 'utils/api';
import { chains, EXPLORER_ENDPOINT, nativeTokens } from 'chains';
import { useAccount } from 'providers/AccountProvider';
import { useSettings } from 'providers/SettingProvider';
import { useScan } from 'providers/ScanProvider';

import './styles.scss';
import AddWalletModal from './AddWalletModal';

function WalletScan() {
    const { account } = useAccount();
    const { settings } = useSettings();
    const {
        address,
        setSelectedAddress,
        walletTokens,
        walletBalance,
        chainBalance,
    } = useScan();

    const [inputAddress, setInputAddress] = useState('');
    const [openModal, setOpenModal] = useState(false);

    const handleKeyDown = async (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            const accounts = await getAccountsByWallet(inputAddress);

            const registeredAccounts = accounts.wallets.filter(
                acc =>
                    acc.AccountId.toString() ===
                        account?.account?.AccountId?.toString() &&
                    acc.Wallet === inputAddress,
            );

            if (!registeredAccounts.length) {
                setOpenModal(true);
                return;
            }

            setSelectedAddress(inputAddress);
        }
    };

    return (
        <div className='scan'>
            <div className='scan__address-input'>
                <Search className='scan__address-input__icon' />
                <Input
                    placeholder='Input Wallet Address'
                    className='scan__address-input__input'
                    value={inputAddress}
                    onChange={e => setInputAddress(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
            </div>

            {/* {walletBalance > 0 && ( */}
            <div className='scan__tokens'>
                <div className='scan__tokens__total'>
                    <div>{formatAddress(address)}</div>
                    <div className='scan__tokens__total__balance'>
                        {`Balance: $${formatCost(walletBalance)}`}
                    </div>
                </div>

                <ScrollArea className='scan__tokens__wrapper'>
                    {chains
                        .filter(chain => settings.Chains.includes(chain.id))
                        .filter(
                            chain =>
                                chainBalance[chain.id] &&
                                chainBalance[chain.id] > 0,
                        )
                        .sort((a, b) => chainBalance[b.id] - chainBalance[a.id])
                        .map(
                            chain =>
                                walletTokens[chain.id] !== undefined && (
                                    <div
                                        key={chain.id}
                                        className='scan__tokens__container'
                                    >
                                        <div className='scan__tokens__chain'>
                                            {chain.name}
                                        </div>
                                        {walletTokens[chain.id]
                                            .filter(
                                                (item: any) =>
                                                    !settings.BlockedTokens.includes(
                                                        item.id,
                                                    ),
                                            )
                                            .map((item: any) =>
                                                nativeTokens.includes(
                                                    item.id.toString(),
                                                ) ? (
                                                    <button
                                                        key={item.id}
                                                        type='button'
                                                        className='scan__tokens__token'
                                                    >
                                                        <div className='scan__tokens__token__info'>
                                                            <img
                                                                className='scan__tokens__token__info__logo'
                                                                src={
                                                                    item.logo_url
                                                                }
                                                                width={36}
                                                                height={36}
                                                                alt='token'
                                                            />
                                                            <div>
                                                                {item.name}
                                                            </div>
                                                        </div>
                                                        <div className='scan__tokens__token__balance'>
                                                            <div>
                                                                {`${formatCost(
                                                                    item.amount,
                                                                )} ${
                                                                    item.symbol
                                                                }`}
                                                            </div>
                                                            <div>
                                                                {`$${formatCost(
                                                                    item.amount *
                                                                        item.price,
                                                                )}`}
                                                            </div>
                                                        </div>
                                                    </button>
                                                ) : (
                                                    <Link
                                                        key={item.id}
                                                        to={`${
                                                            EXPLORER_ENDPOINT[
                                                                chain.id
                                                            ]
                                                        }token/${item.id}`}
                                                        className='scan__tokens__token'
                                                        target='_blank'
                                                        rel='noreferrer'
                                                    >
                                                        <div className='scan__tokens__token__info'>
                                                            <img
                                                                className='scan__tokens__token__info__logo'
                                                                src={
                                                                    item.logo_url
                                                                }
                                                                width={36}
                                                                height={36}
                                                                alt='token'
                                                            />
                                                            <div>
                                                                {item.name}
                                                            </div>
                                                        </div>
                                                        <div className='scan__tokens__token__balance'>
                                                            <div>
                                                                {`${formatCost(
                                                                    item.amount,
                                                                )} ${
                                                                    item.symbol
                                                                }`}
                                                            </div>
                                                            <div>
                                                                {`$${formatCost(
                                                                    item.amount *
                                                                        item.price,
                                                                )}`}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ),
                                            )}
                                    </div>
                                ),
                        )}
                </ScrollArea>
            </div>
            {/* )} */}

            <AddWalletModal
                address={inputAddress}
                open={openModal}
                setOpen={setOpenModal}
            />
        </div>
    );
}

export default WalletScan;
