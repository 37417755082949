import React from 'react';
import TelegramAuthPage from 'containers/TelegramAuthPage';
import Menu from 'containers/Menu';
import RequiresAuth from 'containers/RequiresAuth';
import RequiresAnonymous from 'containers/RequiresAnonymous';
import OptionalAuth from 'containers/OptionalAuth';
import HomePage from 'containers/HomePage';
import { WhitePaper } from 'containers/WhitepaperPage';
import NotFoundPage from 'containers/NotFoundPage';
import PortfolioPage from 'containers/PortfolioPage';
import PnlPage from 'containers/PnlPage';
import SettingsPage from 'containers/SettingsPage';

export interface IRoute {
    url: string;
    component: React.ReactElement;
}

export const Routes: IRoute[] = [
    {
        url: '/',
        component: (
            <OptionalAuth>
                <>
                    <Menu />
                    <HomePage />
                </>
            </OptionalAuth>
        ),
    },
    {
        url: '/telegram/auth',
        component: (
            <RequiresAnonymous>
                <>
                    <Menu />
                    <TelegramAuthPage />
                </>
            </RequiresAnonymous>
        ),
    },
    {
        url: '/scan',
        component: (
            <RequiresAuth>
                <>
                    <Menu />
                    <HomePage />
                </>
            </RequiresAuth>
        ),
    },
    {
        url: '/portfolio',
        component: (
            <RequiresAuth>
                <>
                    <Menu />
                    <PortfolioPage />
                </>
            </RequiresAuth>
        ),
    },
    {
        url: '/pnl',
        component: (
            <RequiresAuth>
                <>
                    <Menu />
                    <PnlPage />
                </>
            </RequiresAuth>
        ),
    },
    {
        url: '/settings',
        component: (
            <RequiresAuth>
                <>
                    <Menu />
                    <SettingsPage />
                </>
            </RequiresAuth>
        ),
    },
    {
        url: '/whitepaper',
        component: (
            <OptionalAuth>
                <>
                    <Menu />
                    <WhitePaper />
                </>
            </OptionalAuth>
        ),
    },
    {
        url: '*',
        component: (
            <OptionalAuth>
                <>
                    <Menu />
                    <NotFoundPage />
                </>
            </OptionalAuth>
        ),
    },
];
