import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHeadsObserver } from '../hooks/hooks';
import './styles.scss';
import { Props } from './interface';

function TableOfContent({ drawerFunc }: Props) {
    const [headings, setHeadings] = useState<any[]>([]);
    const { activeId } = useHeadsObserver();
    const [showDrawer, setShowDrawer] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        const elements = Array.from(
            document.querySelectorAll('h2, h3, h4'),
        ).map((elem: any) => ({
            id: elem.id,
            text: elem.innerText,
            level: Number(elem.nodeName.charAt(1)),
        }));
        setHeadings(elements);
    }, []);

    return (
        <div>
            {showDrawer ? (
                <div className='tableofcontent-container'>
                    <button
                        className='tableofcontent-container__close-btn'
                        type='button'
                        onClick={() => {
                            setShowDrawer(false);
                            drawerFunc(false);
                        }}
                    >
                        {'<<<'}
                    </button>
                    <nav className='tableofcontent'>
                        <ul className='tableofcontent__ulist'>
                            {headings.map(heading => (
                                <div
                                    key={heading.id}
                                    className='tableofcontent__ulist__list'
                                >
                                    <li
                                        className={`tableofcontent__ulist__list-head${heading.level}`}
                                    >
                                        <a
                                            href={`#${heading.id}`}
                                            onClick={e => {
                                                e.preventDefault();
                                                document
                                                    .querySelector(
                                                        `#${heading.id}`,
                                                    )
                                                    ?.scrollIntoView({
                                                        behavior: 'smooth',
                                                    });
                                            }}
                                            className={classNames({
                                                tableofcontent__active:
                                                    activeId === heading.id,
                                            })}
                                        >
                                            {heading.text}
                                        </a>
                                    </li>
                                </div>
                            ))}
                        </ul>
                    </nav>
                </div>
            ) : (
                <div className='tableofcontent-open-btn'>
                    <button
                        type='button'
                        onClick={() => {
                            setShowDrawer(true);
                            drawerFunc(true);
                        }}
                    >
                        {`>>> ${t('whitepaper.TABLEOFCONTENTS')}`}
                    </button>
                </div>
            )}
        </div>
    );
}

export default TableOfContent;
