import Web3 from 'web3';
// import { Connection, LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import axios from 'axios';
// import { Metaplex } from '@metaplex-foundation/js';
// import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import appConfig from 'config';
import { getBlockedTokensByUserId } from './api';

// const SOLANA_CONNECTION = new Connection('https://api.mainnet-beta.solana.com');

const hashShift = 3; // Shift amount

const debankURL = 'https://pro-openapi.debank.com/v1/user';
const infuraUrl =
    'https://mainnet.infura.io/v3/f211a367c4b348cda8c897a56e72099b';

const web3 = new Web3(new Web3.providers.HttpProvider(infuraUrl));

export function verifyAddress(address: string) {
    return Web3.utils.isAddress(address);
}

// export function verifySolanaAddress(address: string) {
//     if (address.length < 32 || address.length > 44) {
//         return false;
//     }

//     // Use the web3.js library to perform checksum validation
//     try {
//         const publicKey = new PublicKey(address);
//         return PublicKey.isOnCurve(publicKey);
//     } catch (error) {
//         return false;
//     }
// }

// export async function getBalance(address: string) {
//     const publicKey = new PublicKey(address);
//     const balance = await SOLANA_CONNECTION.getBalance(publicKey);

//     const url =
//         'https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd';
//     const res = await axios.get(url);
//     const { solana } = res.data;
//     const exchangeRate = solana.usd;
//     return {
//         balance: (1.0 * balance) / LAMPORTS_PER_SOL,
//         price: exchangeRate,
//     };
// }

const getSolPair = async (tokenAddress: string) => {
    const url = `https://api.dexscreener.com/latest/dex/tokens/${tokenAddress}`;
    const res = await axios.get(url);

    const { pairs } = res.data;
    if (pairs === null || pairs === undefined) {
        throw new Error('No pair');
    }

    const pair = pairs.find(
        ({ quoteToken }: any) =>
            quoteToken.address ===
            'So11111111111111111111111111111111111111112',
    );
    if (pair === null || pair === undefined) {
        throw new Error('No pair');
    }
    return pair;
};

// const getSolTokenMetadata = async (mintAddress: string) => {
//     const metaplex = new Metaplex(SOLANA_CONNECTION);
//     return metaplex
//         .nfts()
//         .findByMint({ mintAddress: new PublicKey(mintAddress) });
// };

// const getSolTokenAccountsByOwner = async (ownerAddress: string) => {
//     const url = `https://solana-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_API_KEY}`;
//     const res = await axios.post(
//         url,
//         {
//             id: 1,
//             jsonrpc: '2.0',
//             method: 'getTokenAccountsByOwner',
//             params: [
//                 ownerAddress,
//                 {
//                     programId: TOKEN_PROGRAM_ID,
//                 },
//                 {
//                     encoding: 'jsonParsed',
//                 },
//             ],
//         },
//         {
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//         },
//     );
//     const { result } = res.data;
//     if (result && result.value) {
//         return result.value;
//     }
//     throw new Error('Error in obtain data for api...');
// };

// const fetchSolToken = async (ownerAddress: string) => {
//     const res = [];
//     const tokenAccounts = await getSolTokenAccountsByOwner(ownerAddress);
//     tokenAccounts.sort((a: any, b: any) =>
//         a.account.data.parsed.info.mint.localeCompare(
//             b.account.data.parsed.info.mint,
//         ),
//     );

//     tokenAccounts.map(async (acc: any) => {
//         const tokenAccount = acc.account;
//         const mintAddress = tokenAccount.data.parsed.info.mint;
//         const tokenAmount = tokenAccount.data.parsed.info.tokenAmount.uiAmount;
//         let pair;
//         let metadata;

//         if (tokenAmount === 0) {
//             return;
//         }

//         try {
//             pair = await getSolPair(mintAddress);
//         } catch {
//             metadata = await getSolTokenMetadata(mintAddress);
//         }

//         const priceUsd = pair ? parseFloat(pair.priceUsd) : 0;

//         res.push({
//             id: mintAddress,
//             name: pair?.baseToken?.name || metadata?.name,
//             amount: tokenAmount,
//             price: priceUsd,
//             chain: 'sol',
//         });
//     });
//     const solBalance = await getBalance(ownerAddress);
//     res.push({
//         id: 'So11111111111111111111111111111111111111112',
//         name: 'SOL',
//         amount: solBalance.balance,
//         price: solBalance.price,
//         chain: 'sol',
//     });

//     return { data: res };
// };

export async function getPreviousPrice(
    chains: any,
    date: any,
    filteredTokens: any,
) {
    const url = `https://pro-openapi.debank.com/v1/token/history_price`;
    const headers = {
        AccessKey: appConfig.DEBANK_API_KEY,
    };

    const promises = chains.map(async (chain: string) => {
        const tokensChain = filteredTokens.filter(
            (token: any) => token.chain === chain,
        );

        if (chain === 'sol') {
            const tokenRequests = tokensChain.map(async (token: any) => {
                try {
                    const pair = await getSolPair(token);
                    const priceUsd = pair ? parseFloat(pair.priceUsd) : 0;
                    return {
                        price: priceUsd,
                    };
                } catch {
                    return {
                        price: 0,
                    };
                }
            });

            const responses = await Promise.all(tokenRequests);
            return responses.map(res => {
                return {
                    price: res?.priceUsd || 0,
                };
            });
        }

        const tokenRequests = tokensChain.map(async (token: any) => {
            try {
                const res = await axios.get(
                    `${url}?id=${token.id}&chain_id=${token.chain}&date_at=${date}`,
                    { headers },
                );
                return res.data;
            } catch {
                return {
                    price: 0,
                };
            }
        });

        const responses = await Promise.all(tokenRequests);
        return responses;
    });

    const previousPrice = await Promise.all(promises);
    const price = previousPrice.flat();

    return price;
}

export const fetchToken = async (address: string) => {
    // if (!verifyAddress(address) && verifySolanaAddress(address)) {
    //     return fetchSolToken(address);
    // }
    const headers = {
        AccessKey: appConfig.DEBANK_API_KEY,
    };
    const tokens = await axios.get(
        `${debankURL}/all_token_list?id=${address}`,
        {
            headers,
        },
    );

    return tokens;
};

export const getGasPrice = async () => {
    const gasPrice = await web3.eth.getGasPrice();
    return web3.utils.fromWei(gasPrice, 'gwei');
};

export const formatAddress = (address: string) => {
    // Check if the address is a valid Ethereum address
    // if (!/^0x[0-9A-Fa-f]{40}$/.test(address)) {
    //     throw new Error('Invalid Ethereum address');
    // }

    // Extract the first 4 and last 6 characters
    const firstPart = address.slice(0, 6);
    const lastPart = address.slice(-6);

    // Return the formatted address
    return `${firstPart}...${lastPart}`;
};

export const formatNumber = (number: any) => {
    return Number(number).toLocaleString('en-US');
};

export const formatCost = (cost: any) => {
    return Number(cost).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

export const getTotalBalance = async (userId: any, address: string) => {
    const tokens = await fetchToken(address);

    const unwantedTokens: any = [];
    const blocked = await getBlockedTokensByUserId(userId);
    if (blocked.data.BlockedTokens) {
        const { BlockedTokens } = blocked.data;
        BlockedTokens.forEach((token: any) => {
            unwantedTokens.push(token.Token);
        });
    }

    const filteredTokens = tokens.data.filter(
        (token: any) => !unwantedTokens.includes(token.id),
    );

    const totalBalance = filteredTokens.reduce(
        (a: any, b: any) => a + b.amount * b.price,
        0,
    );
    return formatCost(totalBalance);
};

export const getTotalBalanceOnETH = async (address: string) => {
    const headers = {
        AccessKey: appConfig.DEBANK_API_KEY,
    };
    const balances = await axios.get(
        `${debankURL}/total_balance?id=${address}`,
        {
            headers,
        },
    );

    let result = 0;

    /* eslint-disable no-restricted-syntax */
    for (const balance of balances.data.chain_list) {
        if (balance.id === 'eth') {
            result = balance.usd_value;
        }
    }
    return result;
};

export const getDateTime = () => {
    // Create a new Date object to represent the current date and time
    const now = new Date();

    // Get the current year
    const year = now.getFullYear();

    // Get the current month (0-indexed, so January is 0)
    const month = now.getMonth() + 1; // Adding 1 to adjust for 0-indexing

    // Get the current day of the month
    const day = now.getDate();

    // Get the current hours in 24-hour format
    const hours = now.getHours();

    // Get the current minutes
    const minutes = now.getMinutes();

    return [`${day}/${month}/${year}`, `${hours}:${minutes}`];
};

// Encrypt function using Caesar Cipher
export const caesarEncrypt = (plaintext: string) => {
    return plaintext
        .split('')
        .map(char => {
            // Check if character is a letter
            if (char.match(/[a-z]/i)) {
                const code = char.charCodeAt(0);
                const shiftAmount = (code - 65 + hashShift) % 26;
                const shiftedChar = String.fromCharCode(shiftAmount + 65);
                return shiftedChar;
            }
            return char; // Leave non-alphabetic characters unchanged
        })
        .join('');
};

export const caesarDecrypt = (ciphertext: string) => {
    return ciphertext
        .split('')
        .map(char => {
            // Check if character is a letter
            if (char.match(/[a-z]/i)) {
                const code = char.charCodeAt(0);
                const shiftAmount = (code - 65 - hashShift) % 26;
                const shiftedChar = String.fromCharCode(shiftAmount + 65);
                return shiftedChar;
            }
            return char; // Leave non-alphabetic characters unchanged
        })
        .join('');
};

export const getTimeFormat = (timestamp: any) => {
    // Convert timestamp to Date object
    const date = new Date(timestamp);

    // Array of weekday names
    const weekdays = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];

    // Get the weekday
    const weekday = weekdays[date.getUTCDay()];

    // Get hours, minutes, and seconds
    const hours = `0${date.getUTCHours()}`.slice(-2);
    const minutes = `0${date.getUTCMinutes()}`.slice(-2);
    const seconds = `0${date.getUTCSeconds()}`.slice(-2);

    // Get day, month, and year
    const day = `0${date.getUTCDate()}`.slice(-2);
    const month = `0${date.getUTCMonth() + 1}`.slice(-2); // Month is zero-based, so we add 1
    const year = date.getUTCFullYear();

    // Construct the formatted date string
    const formattedDate = `${weekday}, ${hours}:${minutes}:${seconds}, ${day}/${month}/${year} in UTC`;
    return formattedDate;
};

export const isValidURL = (url: string) => {
    // Regular expression to match URL pattern
    // eslint-disable-next-line prefer-regex-literals
    const urlRegex = new RegExp(
        '^(http(s)?:\\/\\/)?([\\w-]+\\.)+[\\w-]+(\\/[\\w- .\\/\\?%&=]*)?$',
    );

    // Test if the given URL matches the regex pattern
    return urlRegex.test(url);
};

export const calculateRate = (current: any, previous: any) => {
    if (!Number(current) && !Number(previous)) return '+0.00%';
    if (!Number(current)) return '-99.99%';
    if (!Number(previous)) return '+0.00%';
    if (Number(current) - Number(previous) >= 0)
        return `+${formatCost(
            ((Number(current) - Number(previous)) / Number(previous)) * 100,
        )}%`;
    return `${formatCost(
        ((Number(current) - Number(previous)) / Number(previous)) * 100,
    )}%`;
};

export const calculateProfit = (current: any, previous: any) => {
    if (current < previous) {
        return `-$${formatCost(Number(previous) - Number(current))}`;
    }
    return `$${formatCost(Number(current) - Number(previous))}`;
};

export const analyzeRate = (rate: any) => {
    return Number(rate.slice(0, rate.length - 1));
};
