import React from 'react';
/* eslint-disable react/no-array-index-key */
import Footer from 'components/Footer';
import './styles.scss';
import MyPortfolio from './components/MyPortfolio';

function PortfolioPage() {
    return (
        <div className='portfolio-page'>
            <div className='portfolio-page__portfolio'>
                <MyPortfolio />
            </div>
            <Footer />
        </div>
    );
}

export default PortfolioPage;
