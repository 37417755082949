import React from 'react';
import { useTranslation } from 'react-i18next';
import { FullPageProps } from './interface';

function FullPage({ children }: FullPageProps) {
    const { ready } = useTranslation();

    if (!ready) return null;

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
}

export default FullPage;
