export const bannerMotion = {
    bannerImage: {
        rest: { y: 0 },
        animated: {
            y: [-100, 0],
            transition: {
                type: 'spring',
                stiffness: 6,
                delay: 0.1,
                duration: 7,
            },
        },
    },
    preTitle: {
        rest: { x: '-100vw' },
        animated: {
            x: 0,
            transition: {
                duration: 2,
            },
        },
    },
    logo: {
        rest: { opacity: 0 },
        animated: {
            opacity: 1,
            transition: {
                opacity: {
                    repeat: 4,
                    duration: 1,
                },
            },
        },
    },
    title: {
        rest: { x: '100vw' },
        animated: {
            scale: [0.9, 1.1, 1],
            opacity: [0.4, 1, 0.4, 1],
            x: 0,
            transition: {
                duration: 2,
            },
        },
    },
    btnOne: {
        rest: { x: '-100vw' },
        animated: {
            opacity: [0.8, 1, 0.8, 1],
            x: 0,
            transition: {
                type: 'spring',
                stiffness: 100,
                delay: 0,
                duration: 6,
            },
        },
    },
    btnTwo: {
        rest: { x: '100vw' },
        animated: {
            opacity: [0.8, 1, 0.8, 1],
            x: 0,
            transition: {
                type: 'spring',
                stiffness: 100,
                delay: 0,
                duration: 6,
            },
        },
    },
    features: {
        rest: { opacity: 0, scale: 1 },
        animated: {
            opacity: 1,
            y: [100, 0, 20, 0, 20],
            transition: {
                delay: 0.5,
                default: { ease: 'linear' },
                y: {
                    duration: 4,
                },
            },
        },
        onHover: {
            scale: 1.1,
            transition: {
                duration: 1,
            },
        },
        onTap: {
            scale: 1.1,
        },
    },
};

export const videoMotion = {
    title: {
        rest: { y: -50, opacity: 0 },
        animated: {
            opacity: 1,
            y: [-50, 0, 10, 0, 10, 0],
            transition: {
                type: 'spring',
                stiffness: 10,
                duration: 2,
            },
        },
    },
    video: {
        rest: { opacity: 0 },
        animated: {
            opacity: 1,
            transition: { duration: 2 },
        },
        onHover: {
            scale: [1, 1.04],
            transition: { duration: 0.2 },
        },
    },
};

export const howItWorksMotion = {
    container: {
        rest: { opacity: 0 },
        animated: { opacity: 1, transition: { duration: 1.5 } },
    },
    bgImage: {
        rest: { scale: 1 },
        animated: {
            scale: [1.1, 1],
            transition: {
                duration: 10,
            },
        },
    },
    title: {
        rest: { opacity: 0, scale: 1 },
        animated: {
            opacity: [1, 0, 1],
            scale: [1, 1.2, 1],
            transition: {
                duration: 2.5,
            },
        },
    },
    text: {
        rest: { y: 200 },
        animated: {
            y: [200, 0, 20, 0],
            transition: {
                type: 'spring',
                stiffness: 10,
                duration: 1,
            },
        },
    },
};

export const featuresMotion = {
    itemLeftOne: {
        rest: { y: -100, opacity: 0 },
        animated: {
            y: [-100, 0, -10, 0],
            opacity: 1,
            transition: {
                type: 'spring',
                stiffness: 100,
                duration: 2,
            },
        },
    },
    itemLeftTwo: {
        rest: { x: -100, opacity: 0 },
        animated: {
            x: [-100, 0, -10, 0],
            opacity: 1,
            transition: {
                type: 'spring',
                stiffness: 100,
                duration: 2,
            },
        },
    },
    itemLeftThree: {
        rest: { y: 100, opacity: 0 },
        animated: {
            y: [100, 0, 10, 0],
            opacity: 1,
            transition: {
                type: 'spring',
                stiffness: 100,
                duration: 2,
            },
        },
    },
    imageOne: {
        rest: { x: 200, rotate: -25, opacity: 0 },
        animated: { x: [200, 0, 10, 0], rotate: 0, opacity: 1 },
    },
    imageTwo: {
        rest: { x: -200, rotate: 5, opacity: 0 },
        animated: { x: [-200, 0, -5, 0], rotate: 0, opacity: 1 },
    },
    itemRightOne: {
        rest: { y: -100, opacity: 0 },
        animated: {
            y: [-100, 0, -10, 0],
            opacity: 1,
            transition: {
                type: 'spring',
                stiffness: 100,
                duration: 2,
            },
        },
    },
    itemRightTwo: {
        rest: { x: 100, opacity: 0 },
        animated: {
            x: [100, 0, -10, 0],
            opacity: 1,
            transition: {
                type: 'spring',
                stiffness: 100,
                duration: 2,
            },
        },
    },
    itemRightThree: {
        rest: { y: 100, opacity: 0 },
        animated: {
            y: [100, 0, 10, 0],
            opacity: 1,
            transition: {
                type: 'spring',
                stiffness: 100,
                duration: 2,
            },
        },
    },
};

export const exploreMotion = {
    wrapper: {
        rest: { opacity: 0 },
        animated: {
            opacity: 1,
            transition: {
                delay: 1,
                duration: 1,
            },
        },
    },
    preTitle: {
        rest: { x: [-301, 300], opacity: 0 },
        animated: { x: 0, opacity: 1, transition: { duration: 1 } },
    },
    title: {
        rest: { x: [-401, 400], opacity: 0 },
        animated: { x: 0, opacity: 1, transition: { duration: 2 } },
    },
    text: {
        rest: { y: 100, opacity: 0 },
        animated: { y: 0, opacity: 1, transition: { duration: 2 } },
    },
    btn: {
        rest: { opacity: 1 },
        animated: {
            opacity: 1,
            transition: {
                duration: 1,
                ease: 'easeIn',
            },
        },
    },
    image: {
        rest: { opacity: 0.8, scale: 0.8, x: 300 },
        animated: {
            opacity: 1,
            scale: 1,
            x: 0,
            transition: { duration: 1, ease: 'easeIn' },
        },
    },
};

export const tokenMotion = {
    wrapperOne: {
        rest: { x: [-401, -400] },
        animated: {
            x: [0, -40, 0],
            transition: {
                type: 'spring',
                stiffness: 100,
                duration: 2,
            },
        },
    },
    chart: {
        rest: { rotate: 0 },
        animated: {
            rotate: 360,
            transition: {
                type: 'spring',
                delay: 2.5,
                duration: 1,
            },
        },
    },
    boxText: { rest: { scale: 1 }, animated: { scale: 1.2 } },
    wrapperTwo: {
        rest: { x: [201, 200] },
        animated: {
            x: [0, 40, 0],
            transition: {
                type: 'spring',
                stiffnes: 100,
                duration: 2,
            },
        },
    },
};

export const tokenMobileMotion = {
    wrapperOne: {
        rest: { x: [-201, -200] },
        animated: { x: [0, -10, 0] },
    },
    chart: { rest: { rotate: 0 }, animated: { rotate: 360 } },
    boxText: { rest: { scale: 1 }, animated: { scale: 1.2 } },
    wrapperTwo: {
        rest: { x: [201, 200] },
        animated: { x: [0, 10, 0] },
    },
};
