import React, { useContext, useMemo, useState } from 'react';
import { AccountSummaryDTO } from 'DTO/accountDTO';
import { axios } from '../startup';

interface IAccountProviderProps {
    children: JSX.Element;
}

interface IAccountContext {
    account?: AccountSummaryDTO;
    setAccount?: React.Dispatch<
        React.SetStateAction<AccountSummaryDTO | undefined>
    >;
    setFetchTry?: React.Dispatch<React.SetStateAction<boolean>>;
    fetchAccount?: (callback: (success: boolean) => void) => void;
    fetchTry?: boolean;
}

const AccountContext = React.createContext<IAccountContext>({});

export const useAccount = () => useContext(AccountContext);

function AccountProvider({ children }: IAccountProviderProps) {
    const [account, setAccount] = useState<AccountSummaryDTO>();
    const [fetchTry, setFetchTry] = useState<boolean>(false);

    const value = useMemo<IAccountContext>(
        () => ({
            account,
            setAccount,
            setFetchTry,
            fetchAccount: callback => {
                axios
                    .get('/accounts')
                    .then((res: any) => {
                        setAccount(res.data);
                        callback(true);
                    })
                    .catch(() => callback(false))
                    .finally(() => setFetchTry(true));
            },
            fetchTry,
        }),
        [account, fetchTry],
    );

    return (
        <AccountContext.Provider value={value}>
            {children}
        </AccountContext.Provider>
    );
}

export default AccountProvider;
