const config = {
    ADMIN_WALLET: process.env.REACT_APP_ADMIN_WALLET,
    API_URL: process.env.REACT_APP_API_URL,
    BOT_NAME: process.env.REACT_APP_BOT_NAME ?? '',
    THIRD_WEB: process.env.REACT_APP_THIRD_WEB ?? '',
    PROJECT_ID: process.env.REACT_APP_PROJECT_ID ?? '',
    isDevelopment: process.env.NODE_ENV === 'development',
    DEBANK_API_KEY: process.env.REACT_APP_DEBANK_API_KEY,
};

export default config;
