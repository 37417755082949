import { Button } from 'antd';
import classNames from 'classnames';
import React, { Ref } from 'react';
import { HomepageButtonProps } from './interface';
import './styles.scss';

function HomepageButton(
    {
        children,
        appendElement,
        onClick,
        type,
        className,
        ...props
    }: HomepageButtonProps,
    ref: Ref<HTMLElement>,
) {
    return (
        <Button
            ref={ref}
            className={classNames(
                'homepage-button',
                `homepage-button--${type}`,
                {
                    'homepage-button--icon': appendElement,
                },
                className,
            )}
            onClick={onClick}
            {...props}
        >
            {children}
            {!!appendElement && (
                <div className='homepage-button__icon-wrapper'>
                    {appendElement}
                </div>
            )}
        </Button>
    );
}

export default React.forwardRef(HomepageButton);
