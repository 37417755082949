export const footerMotion = {
    container: {
        rest: { opacity: 0 },
        animated: { opacity: 1, transition: { duration: 1 } },
    },
    image: {
        rest: { x: [-201, 200], y: [101, 100] },
        animated: {
            x: [-200, 0],
            y: [0, 0],
            transition: { duration: 1 },
        },
    },
    social: {
        rest: { x: [201, 200], y: [101, 100] },
        animated: {
            x: [200, 0],
            y: [0, 0],
            transition: { duration: 1 },
        },
    },
};
