import React from 'react';
import { ColumnDef } from '@tanstack/react-table';

import { DataTableColumnHeader } from 'components/DataTable/data-table-column-header';
import { DataTableRowActions } from 'components/DataTable/data-table-row-actions';

import { formatCost } from 'utils/debank';
import { Wallet } from './schema';

export const columns: ColumnDef<Wallet>[] = [
    {
        accessorKey: 'Username',
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title='Name' />
        ),
        cell: ({ row }) => (
            <div className='w-[80px]'>{row.getValue('Username')}</div>
        ),
        enableHiding: false,
    },
    {
        accessorKey: 'Wallet',
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title='Address' />
        ),
        cell: ({ row }) => (
            <div className='w-[80px]'>{row.getValue('Wallet')}</div>
        ),
        enableSorting: false,
        enableHiding: false,
    },
    {
        accessorKey: 'Balance',
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title='Balance' />
        ),
        cell: ({ row }) => {
            return (
                <span className='max-w-[500px] truncate font-medium'>
                    ${formatCost(row.getValue('Balance'))}
                </span>
            );
        },
    },
    {
        id: 'actions',
        cell: ({ row }) => <DataTableRowActions row={row} />,
    },
];
