import React from 'react';
import footerLogo from 'assets/images/footer_logo.png';
import medium from 'assets/icons/medium.svg';
import telegram from 'assets/icons/telegram.svg';
import twitter from 'assets/icons/twitter.svg';
import youtube from 'assets/icons/youtube.svg';
// import footerLogo from 'assets/images/logo.png';
import { motion } from 'framer-motion';
import { footerMotion } from './animations';
import './styles.scss';

function Footer() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    return (
        <motion.div
            initial='rest'
            whileInView='animated'
            viewport={{ once: true }}
            variants={footerMotion.container}
            className='footer'
        >
            <div className='footer__social-wrapper'>
                <motion.img
                    initial='rest'
                    whileInView='animated'
                    viewport={{ once: true }}
                    variants={footerMotion.image}
                    src={footerLogo}
                    className='home-page__banner__logo'
                    alt=''
                />
                <div className='footer__line' />
                <motion.div
                    initial='rest'
                    whileInView='animated'
                    viewport={{ once: true }}
                    variants={footerMotion.social}
                    className='footer__social'
                >
                    <a
                        href='https://t.me/TrackerAI_ERC'
                        target='_blank'
                        rel='noreferrer'
                    >
                        <img src={telegram} alt='Telegram Icon' />
                    </a>
                    <a
                        href='https://x.com/TrackerAI_ERC '
                        target='_blank'
                        rel='noreferrer'
                    >
                        <img src={twitter} alt='Twitter Icon' />
                    </a>
                    <a
                        href='https://medium.com/@TrackerAI_ERC'
                        target='_blank'
                        rel='noreferrer'
                    >
                        <img src={medium} alt='Medium Icon' />
                    </a>
                </motion.div>
            </div>
            <div className='footer__copyright'>
                © {currentYear} Tracker AI{' '}
            </div>
        </motion.div>
    );
}

export default Footer;
