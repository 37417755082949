import { axios } from 'startup';

export async function getBlockedTokensByUserId(userId: string) {
    const response = await axios.get(
        `/settings/getBlockedTokensByUserId?userId=${userId}`,
    );
    return response;
}

export async function getAccountsByWallet(wallet: string) {
    const response = await axios.get(
        `/accounts/getAccountsByWallet?wallet=${wallet}`,
    );
    return response.data;
}
