import { useEffect, useState } from 'react';
import Web3 from 'web3';
import axios from 'axios';
import { ethers } from 'ethers';

import { WalletDTO } from 'DTO/walletDTO';
import { calculateRate, getPreviousPrice } from 'utils/debank';
import {
    convertDateToHour,
    convertDateToShort,
    getDateFromDateTime,
    getDateTime,
} from 'utils/date';
import { sleep } from 'utils/delay';

export const usePortfolioData = (
    wallet: WalletDTO[],
    chains: string[],
    thresold: number,
    blockedTokens: string[],
    dateRange: string,
) => {
    const [walletTokens, setWalletTokens] = useState<any>({});
    const [chainBalance, setChainBalance] = useState<any>({});
    const [totalBalance, setTotalBalance] = useState(0);
    const [totalBalanceRate, setTotalBalanceRate] = useState('0');
    const [portfolioHistory, setPortfolioHistory] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    const getPortfolioData = async () => {
        const tokenData: any = {};
        const balance: any = {};
        const oldBalance = 0;

        let fromDate = Date.now();
        if (dateRange === '24H') {
            fromDate -= 24 * 60 * 60 * 1000;
        }
        if (dateRange === '7D') {
            fromDate -= 7 * 24 * 60 * 60 * 1000;
        }
        if (dateRange === '1M') {
            fromDate -= 30 * 24 * 60 * 60 * 1000;
        }
        const now = new Date();

        await Promise.all(
            wallet.map(async (wallet1: WalletDTO) => {
                await Promise.all(
                    wallet1.Token.map(async (token1: any) => {
                        if (tokenData[token1.chain] === undefined) {
                            tokenData[token1.chain] = {};
                        }
                        const previousPrice = await getPreviousPrice(
                            [token1.chain],
                            new Date(fromDate).toLocaleDateString('en-CA'),
                            [token1],
                        );
                        if (
                            // eslint-disable-next-line no-prototype-builtins
                            tokenData[token1.chain].hasOwnProperty(token1.id)
                        ) {
                            tokenData[token1.chain][token1.id].amount +=
                                token1.amount;
                        } else {
                            tokenData[token1.chain][token1.id] = {};
                            tokenData[token1.chain][token1.id].id = token1.id;
                            tokenData[token1.chain][token1.id].name =
                                token1.name;
                            tokenData[token1.chain][token1.id].price =
                                token1.price;
                            tokenData[token1.chain][token1.id].amount =
                                token1.amount;
                            tokenData[token1.chain][token1.id].symbol =
                                token1.symbol;
                            tokenData[token1.chain][token1.id].logo_url =
                                token1.logo_url;
                            tokenData[token1.chain][token1.id].rate =
                                calculateRate(
                                    token1.price,
                                    previousPrice[0].price,
                                );
                        }

                        balance[token1.chain] =
                            (balance[token1.chain] || 0) +
                            token1.amount * token1.price;
                        // oldBalance += token1.amount * previousPrice[0].price;
                    }),
                );
            }),
        );
        // const total = wallet.reduce((a: number, b: WalletDTO) => {
        //     const tokenBalance = b.Token.filter(el => chains.includes(el.chain))
        //         .filter(el => !blockedTokens.includes(el.id))
        //         .reduce((c: number, d: any) => c + d.price * d.amount, 0);
        //     if (tokenBalance >= thresold) return a + tokenBalance;
        //     return a;
        // }, 0);
        const total = wallet.reduce((a: number, b: WalletDTO) => {
            const tokenBalance = b.Token.reduce(
                (c: number, d: any) => c + d.price * d.amount,
                0,
            );
            return a + tokenBalance;
        }, 0);
        setChainBalance(balance);
        setWalletTokens(tokenData);

        return total;
    };

    const getPortfolioHistory = async () => {
        setLoading(true);

        const total = await getPortfolioData();

        const RPC_URL = process.env.REACT_APP_RPC_URL;
        const ENDPOINT = process.env.REACT_APP_ENDPOINT;
        const ETHERSCAN_API_KEY = process.env.REACT_APP_ETHERSCAN_API_KEY;
        const DEBANK_API_KEY = process.env.REACT_APP_DEBANK_API_KEY;
        const web3 = new Web3(RPC_URL);

        const portfolioHistoryData: any = [];
        const currentBlock = await web3.eth.getBlockNumber();
        let startBlock = Number(currentBlock) - 10;

        if (dateRange === '24H') {
            startBlock -= 5 * 60 * 24;
        } else if (dateRange === '7D') {
            startBlock -= 7 * 5 * 60 * 24;
        } else {
            startBlock -= 30 * 5 * 60 * 24;
        }

        await Promise.all(
            wallet.map(async (wallet1: WalletDTO) => {
                const ETH_ETHERSCAN_ENDPOINT = `${ENDPOINT}?module=account&action=txlist&address=${wallet1.Wallet}&startblock=${startBlock}&endblock=${currentBlock}&sort=asc&apikey=${ETHERSCAN_API_KEY}`;

                const responseEth = await axios.get(ETH_ETHERSCAN_ENDPOINT);

                // eslint-disable-next-line no-restricted-syntax
                for (const tx of responseEth.data.result) {
                    if (!tx.blockNumber) {
                        // eslint-disable-next-line no-continue
                        continue;
                    }

                    if (tx.value === '0') {
                        // eslint-disable-next-line no-continue
                        continue;
                    }

                    const date = new Date(
                        Number(tx.timeStamp) * 1000,
                    ).toLocaleDateString('en-CA');

                    const headers = {
                        AccessKey: DEBANK_API_KEY,
                    };
                    const debankURL = `https://pro-openapi.debank.com/v1/token/history_price?chain_id=eth&id=eth&date_at=${date}`;
                    // eslint-disable-next-line no-await-in-loop
                    const price = await axios.get(debankURL, {
                        headers,
                    });

                    const value =
                        parseFloat(price.data.price) *
                        parseFloat(ethers.utils.formatEther(tx.value || 0));
                    if (value) {
                        if (
                            tx.to.toLowerCase() === wallet1.Wallet.toLowerCase()
                        ) {
                            portfolioHistoryData.push({
                                CreationDate: date,
                                value: -value,
                            });
                            // eslint-disable-next-line no-continue
                            continue;
                        }
                        if (
                            tx.from.toLowerCase() ===
                            wallet1.Wallet.toLowerCase()
                        ) {
                            portfolioHistoryData.push({
                                CreationDate: new Date(
                                    Number(tx.timeStamp) * 1000,
                                ),
                                value,
                            });
                            // eslint-disable-next-line no-continue
                            continue;
                        }
                    }

                    // eslint-disable-next-line no-await-in-loop
                    await sleep(100);
                }
                await sleep(1000);
                const TOKEN_ETHERSCAN_ENDPOINT = `${ENDPOINT}?module=account&action=tokentx&address=${wallet1.Wallet}&startblock=${startBlock}&endblock=${currentBlock}&sort=asc&apikey=${ETHERSCAN_API_KEY}`;
                // eslint-disable-next-line no-await-in-loop
                const responseToken = await axios.get(TOKEN_ETHERSCAN_ENDPOINT);

                if (responseToken.data.message === 'OK') {
                    // eslint-disable-next-line no-restricted-syntax
                    for (const tx of responseToken.data.result) {
                        if (!tx.blockNumber) {
                            // eslint-disable-next-line no-continue
                            continue;
                        }

                        const date = new Date(
                            Number(tx.timeStamp) * 1000,
                        ).toLocaleDateString('en-CA');

                        const { contractAddress } = tx;
                        const headers = {
                            AccessKey: DEBANK_API_KEY,
                        };
                        const debankURL = `https://pro-openapi.debank.com/v1/token/history_price?chain_id=eth&id=${contractAddress}&date_at=${date}`;
                        // eslint-disable-next-line no-await-in-loop
                        const price = await axios.get(debankURL, {
                            headers,
                        });
                        const value =
                            parseFloat(
                                ethers.utils.formatUnits(
                                    tx.value,
                                    Number(tx.tokenDecimal) || 18,
                                ),
                            ) * (price.data?.price || 0);

                        if (
                            tx.to.toLowerCase() === wallet1.Wallet.toLowerCase()
                        ) {
                            portfolioHistoryData.push({
                                CreationDate: new Date(
                                    Number(tx.timeStamp) * 1000,
                                ),
                                value: -value,
                            });
                            // eslint-disable-next-line no-continue
                            continue;
                        }
                        if (
                            tx.from.toLowerCase() ===
                            wallet1.Wallet.toLowerCase()
                        ) {
                            portfolioHistoryData.push({
                                CreationDate: new Date(
                                    Number(tx.timeStamp) * 1000,
                                ),
                                value,
                            });
                            // eslint-disable-next-line no-continue
                            continue;
                        }

                        // eslint-disable-next-line no-await-in-loop
                        await sleep(100);
                    }
                }
            }),
        );

        const portfolioDataByDate = portfolioHistoryData.reduce(
            (acc: any, account: any) => {
                const accountDate =
                    dateRange === '24H'
                        ? getDateTime(account.CreationDate).toISOString()
                        : getDateFromDateTime(account.CreationDate);
                acc[accountDate] =
                    (acc[accountDate] || 0) + (account.value || 1);
                return acc;
            },
            {},
        );

        let cumulativeSum = total;
        const cumulativeCountByCreationDate: any = [];

        if (dateRange === '24H') {
            const endDate = new Date();
            const startDate = new Date(endDate);
            startDate.setDate(startDate.getDate() - 1);
            for (
                let date = endDate;
                date >= startDate;
                date.setMinutes(date.getMinutes() - 180)
            ) {
                const dateStr = getDateTime(date).toISOString();
                cumulativeSum += portfolioDataByDate[dateStr] || 0;
                cumulativeCountByCreationDate.push({
                    date: convertDateToHour(dateStr),
                    value: cumulativeSum < 0 ? 0 : cumulativeSum,
                });
            }
        } else {
            const endDate = new Date();
            const startDate = new Date(endDate);

            if (dateRange === '7D') {
                startDate.setDate(startDate.getDate() - 7);
            }
            if (dateRange === '30D') {
                startDate.setMonth(startDate.getMonth() - 1);
            }

            const earliestDate = new Date(
                Math.min(
                    ...portfolioHistoryData.map(
                        account => new Date(account.CreationDate),
                    ),
                ),
            );

            for (
                let date = earliestDate;
                date < startDate;
                date.setDate(date.getDate() + 1)
            ) {
                const dateStr = getDateFromDateTime(date);
                cumulativeSum += portfolioDataByDate[dateStr] || 0;
            }

            for (
                let date = endDate;
                date >= startDate;
                date.setDate(date.getDate() - 1)
            ) {
                const dateStr = getDateFromDateTime(date);
                cumulativeSum += portfolioDataByDate[dateStr] || 0;

                cumulativeCountByCreationDate.push({
                    date: convertDateToShort(dateStr),
                    value: cumulativeSum < 0 ? 0 : cumulativeSum,
                });
            }
        }

        setPortfolioHistory(cumulativeCountByCreationDate.reverse());
        const balanceRate = calculateRate(
            cumulativeCountByCreationDate[
                cumulativeCountByCreationDate.length - 1
            ].value,
            cumulativeCountByCreationDate[0].value,
        );
        setTotalBalanceRate(balanceRate);
        setTotalBalance(total);
        setLoading(false);
    };

    useEffect(() => {
        if (wallet.length > 0) {
            getPortfolioHistory();
        }
    }, [dateRange, wallet]);

    return {
        loading,
        chainBalance,
        portfolioHistory,
        totalBalance,
        totalBalanceRate,
        walletTokens,
    };
};
