import React from 'react';
import { Modal, notification } from 'antd';
import { axios } from 'startup';
import { Button } from 'components/Button';
import { useWallet } from 'providers/WalletProvider';
import { useAccount } from 'providers/AccountProvider';
import { EditWalletModalProps } from './interface';
import './styles.scss';

function EditWalletModal({
    id,
    walletName,
    open,
    setOpen,
}: EditWalletModalProps) {
    const { fetchWallet } = useWallet();
    const { account } = useAccount();

    const [newName, setNewName] = React.useState(walletName);

    const handleEditWallet = async () => {
        const res = await axios.post('accounts/updateWalletUsername', {
            id,
            username: newName,
        });

        if (res.data?.ok === true) {
            notification.success({
                message: 'Wallet updated successfully',
            });
        } else {
            notification.error({
                message: 'Error',
                description: 'Failed to update wallet',
            });
        }

        fetchWallet(account.account.AccountId);
        setOpen(false);
    };

    return (
        <Modal
            title='Edit wallet'
            open={open}
            onCancel={() => {
                setOpen(false);
            }}
            footer={null}
            className='add-wallet'
            centered
            width={100}
        >
            <div className='add-wallet__content'>
                <p className='add-wallet__content__label'>Input wallet name</p>
                <input
                    name='walletName'
                    type='text'
                    className='add-wallet__content__name'
                    value={newName}
                    onChange={ev => setNewName(ev.target.value)}
                />
                <Button
                    className='add-wallet__content__button'
                    onClick={handleEditWallet}
                >
                    Update Wallet
                </Button>
            </div>
        </Modal>
    );
}

export default EditWalletModal;
