import React from 'react';
/* eslint-disable react/no-array-index-key */
import Footer from 'components/Footer';
import './styles.scss';
import Pnl from './components/Pnl';

function PnlPage() {
    return (
        <div className='pnl-page'>
            <div className='pnl-page__pnl'>
                <Pnl />
            </div>
            <Footer />
        </div>
    );
}

export default PnlPage;
