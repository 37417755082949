import React, { useState } from 'react';
import Content from './components/Content';
import TableOfContent from './components/TableOfContents';
import './styles.scss';

export function WhitePaper() {
    const [showDrawer, setShowDrawer] = useState(true);

    const content = (drawer: any) => {
        return setShowDrawer(drawer);
    };

    return (
        <div className='whitepaper__wrapper'>
            <TableOfContent drawerFunc={content} />
            <Content showDrawer={showDrawer} />
        </div>
    );
}
