import { useEffect } from 'react';
import { eraseCookie } from 'utils/cookies';
import { useSettings } from 'providers/SettingProvider';
import { useAccount } from 'providers/AccountProvider';
import { RequireAuthProps } from './interface';

function OptionalAuth({ children }: RequireAuthProps) {
    const { account, fetchTry, fetchAccount } = useAccount();
    const { fetchSettings } = useSettings();

    useEffect(() => {
        if (fetchTry) return;

        fetchAccount!(success => {
            if (success) return;

            eraseCookie('accessToken');
        });
    }, []);

    useEffect(() => {
        if (account?.account?.AccountId)
            fetchSettings!(() => {}, account.account.AccountId);
    }, [account]);

    if (!fetchTry) return null;

    return children;
}

export default OptionalAuth;
