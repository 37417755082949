import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import classNames from 'classnames';
import './styles.scss';

const buttonVariants = cva('button', {
    variants: {
        variant: {
            default: 'button__default',
            destructive: 'button__destructive',
            outline: 'button__outline',
            secondary: 'button__secondary',
            ghost: 'button__ghost',
            link: 'button__link',
        },
        size: {
            default: 'button__default',
            sm: 'button__small',
            lg: 'button__large',
            icon: 'button__icon',
        },
    },
    defaultVariants: {
        variant: 'default',
        size: 'default',
    },
});

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, variant, size, asChild, ...props }, ref) => {
        const Comp = asChild ? Slot : 'button';
        return (
            <Comp
                className={classNames(
                    buttonVariants({ variant, size, className }),
                )}
                ref={ref}
                {...props}
            />
        );
    },
);
Button.displayName = 'Button';
Button.defaultProps = {
    asChild: false,
};

export { Button, buttonVariants };
