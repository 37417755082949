import React from 'react';
import {
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';

import { usePnlData } from 'hooks/usePnlData';

function CustomTooltip({ active, payload }: any) {
    if (active && payload && payload.length > 0) {
        return (
            <div className='chart-customTooltip1'>{`$${payload[0].value.toFixed(
                2,
            )}`}</div>
        );
    }

    return null;
}

export function PnlChart({
    dateRange,
    selectedAddress,
}: {
    dateRange: string;
    selectedAddress: string;
}) {
    const { profitHistory } = usePnlData(selectedAddress, dateRange);

    return (
        <ResponsiveContainer width='100%' height={590} className='pnl-chart'>
            <LineChart data={profitHistory}>
                <Tooltip content={<CustomTooltip />} />
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                    dataKey='date'
                    stroke='#888888'
                    fontSize={12}
                    tickLine={false}
                    axisLine={false}
                />
                <YAxis
                    stroke='#888888'
                    fontSize={12}
                    tickLine={false}
                    axisLine={false}
                    tickFormatter={value => `$${value}`}
                    domain={['auto', 'auto']}
                />
                <Line
                    type='monotone'
                    dataKey='value'
                    fill='currentColor'
                    className='fill-primary'
                    stroke='#3b82f6'
                    strokeWidth={3}
                    activeDot={{ r: 8, style: { fill: '#020817' } }}
                />
            </LineChart>
        </ResponsiveContainer>
    );
}
