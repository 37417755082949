import React from 'react';
import { Modal } from 'antd';
import { axios } from 'startup';
import { Button } from 'components/Button';
import { RadioGroup, RadioGroupItem } from 'components/RadioGroup';
import { useAccount } from 'providers/AccountProvider';
import { useScan } from 'providers/ScanProvider';
import { AddWalletModalProps } from './interface';
import './styles.scss';

function AddWalletModal({ address, open, setOpen }: AddWalletModalProps) {
    const { account } = useAccount();
    const { setSelectedAddress } = useScan();

    const [walletName, setWalletName] = React.useState('');
    const [walletType, setWalletType] = React.useState('myWallet');

    const handleScanWallet = async () => {
        axios.post(`/accounts/addWallet`, {
            username: walletName,
            personal: walletType === 'myWallet',
            accountId: account?.account?.AccountId?.toString(),
            wallet: address,
        });

        setSelectedAddress(address);
        setWalletName('');
        setOpen(false);
    };

    return (
        <Modal
            title='Save wallet'
            open={open}
            onCancel={() => {
                setOpen(false);
            }}
            footer={null}
            className='add-wallet'
            centered
            width={100}
        >
            <div className='add-wallet__content'>
                <RadioGroup
                    className='add-wallet__content__group'
                    value={walletType}
                    onValueChange={value => setWalletType(value)}
                >
                    <div className='add-wallet__content__item'>
                        <RadioGroupItem value='myWallet' />
                        <div>My Wallet</div>
                    </div>
                    <div className='add-wallet__content__item'>
                        <RadioGroupItem value='stalkedWallet' />
                        <div>Stalked Wallet</div>
                    </div>
                </RadioGroup>
                {walletType === 'myWallet' && (
                    <>
                        <p className='add-wallet__content__label'>
                            Input wallet name
                        </p>
                        <input
                            name='walletName'
                            type='text'
                            className='add-wallet__content__name'
                            value={walletName}
                            onChange={ev => setWalletName(ev.target.value)}
                        />
                    </>
                )}
                <Button
                    className='add-wallet__content__button'
                    disabled={walletType === 'myWallet' && !walletName}
                    onClick={handleScanWallet}
                >
                    Scan Wallet
                </Button>
            </div>
        </Modal>
    );
}

export default AddWalletModal;
