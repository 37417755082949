import { WalletDTO } from 'DTO/walletDTO';
import { fetchToken, verifyAddress } from 'utils/debank';

export const getWalletInfo = async (wallets: WalletDTO[]) => {
    /* eslint-disable no-restricted-syntax */
    /* eslint-disable no-await-in-loop */
    const res = [];
    for (const wallet of wallets) {
        if (!verifyAddress(wallet.Wallet)) {
            // eslint-disable-next-line no-continue
            continue;
        }

        const data = await fetchToken(wallet.Wallet);
        const tokens = data.data.filter((one: any) => one.price !== 0);

        const totalBalance = tokens.reduce(
            (a: any, b: any) => a + b.amount * b.price,
            0,
        );

        res.push({ ...wallet, Token: tokens, Balance: totalBalance });
    }
    return res;
};
