import React, { useEffect } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { WalletDTO } from 'DTO/walletDTO';
import { DataTable } from 'components/DataTable';
import { useAccount } from 'providers/AccountProvider';
import { useWallet } from 'providers/WalletProvider';
import { useSettings } from 'providers/SettingProvider';
import { columns } from './columns';
import './styles.scss';

function MyWallets() {
    const { account } = useAccount();
    const { wallet, fetchWallet } = useWallet();
    const { settings } = useSettings();

    useEffect(() => {
        if (account) {
            const { AccountId } = account.account;
            fetchWallet!(AccountId);
        }
    }, [account]);

    return (
        <div className='mywallets'>
            <div className='mywallets__wrapper'>
                <div className='mywallets__wrapper__header'>
                    <h2 className='mywallets__wrapper__header__title'>
                        My Wallets
                    </h2>
                </div>
                <DataTable
                    data={wallet.myWallet.map((el: WalletDTO) => {
                        return {
                            Id: el.Id,
                            Username: el.Username,
                            Wallet: el.Wallet,
                            Balance: el.Token.filter(
                                (val: any) =>
                                    val.amount * val.price >=
                                    settings.MinimumAmount,
                            )
                                .filter(
                                    (val: any) =>
                                        settings.Chains.includes(val.chain) &&
                                        !settings.BlockedTokens.includes(
                                            val.id,
                                        ),
                                )
                                .map((val: any) => val.amount * val.price)
                                .reduce((a, b) => a + b, 0),
                        };
                    })}
                    columns={columns}
                />
            </div>
        </div>
        // <motion.div className={classNames('feature', className)} {...rest}>
        //     <div className='feature__blur' />
        //     <div className='feature__image-wrapper' />
        //     {image}
        //     <div className='feature__info'>
        //         <div className='feature__info__pre-title'>{preTitle}</div>
        //         <div className='feature__info__title'>{title}</div>
        //         <div className='feature__info__text'>{text}</div>
        //     </div>
        // </motion.div>
    );
}

export default MyWallets;
