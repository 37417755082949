import React, { useEffect, useRef } from 'react';
import { getSubscription, subscribe } from 'utils/notifications';
import { Button, Checkbox, notification, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useAccount } from 'providers/AccountProvider';
import { getCookie, setCookie } from 'utils/cookies';
import { axios } from 'startup';

function NotificationsPrompt() {
    const dontShowAgainRef = useRef<boolean>(false);
    const { t } = useTranslation();
    const { account } = useAccount();

    useEffect(() => {
        if (!account) return;
        if (!('serviceWorker' in navigator)) return;
        if (localStorage.getItem('no-notifications-prompt')) return;
        if (getCookie('no-notification-prompt')) return;

        (async () => {
            const sub = await getSubscription();
            if (sub) {
                const resp = await axios.post(
                    '/notifications/checkSubscription',
                    {
                        subscription: sub,
                    },
                );
                if (resp.status !== 200) return;
                const { isSubscribed } = resp.data;
                if (isSubscribed) return;
            }

            const key = `notifications-${Date.now()}`;

            const onNotificationsYes = () => {
                if (Notification.permission === 'default') {
                    Notification.requestPermission(() => {
                        if (Notification.permission === 'granted') {
                            subscribe();
                        } else {
                            notification.error({
                                message: i18next.t('general.ERROR') as string,
                                description: i18next.t(
                                    `notifications.NOTIFICATIONS_NOT_ALLOWED`,
                                ) as string,
                            });
                        }
                    });
                } else if (Notification.permission === 'granted') {
                    subscribe();
                } else {
                    notification.error({
                        message: i18next.t('general.ERROR') as string,
                        description: i18next.t(
                            `notifications.NOTIFICATIONS_NOT_ALLOWED`,
                        ) as string,
                    });
                }

                if (dontShowAgainRef.current) {
                    localStorage.setItem('no-notifications-prompt', 'true');
                    setCookie('no-notification-prompt', 'true');
                }

                notification.destroy(key);
            };

            const onNotificationNo = () => {
                notification.destroy(key);
                if (dontShowAgainRef.current) {
                    localStorage.setItem('no-notifications-prompt', 'true');
                } else {
                    setCookie('no-notification-prompt', 'true', 1);
                }
            };

            const btn = (
                <Space>
                    <Checkbox
                        onChange={ev => {
                            dontShowAgainRef.current = ev.target.checked;
                        }}
                    >
                        {t('notifications.DONT_SHOW_AGAIN')}
                    </Checkbox>
                    <Button type='link' size='small' onClick={onNotificationNo}>
                        {t('general.NO')}
                    </Button>
                    <Button
                        size='small'
                        type='primary'
                        onClick={onNotificationsYes}
                    >
                        {t('general.YES')}
                    </Button>
                </Space>
            );

            notification.open({
                duration: null,
                message: t('notifications.TITLE'),
                description: t('notifications.QUESTION'),
                btn,
                key,
            });
        })();
    }, [account]);

    return null;
}

export default NotificationsPrompt;
