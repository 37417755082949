import { z } from 'zod';

// We're keeping a simple non-relational schema here.
// IRL, you will have a schema for your data models.
export const taskSchema = z.object({
    Id: z.number(),
    Username: z.string().nullable(),
    Wallet: z.string(),
    Balance: z.number(),
});

export type Task = z.infer<typeof taskSchema>;
