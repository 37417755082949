import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { eraseCookie, getCookie } from 'utils/cookies';
import { useAccount } from '../../providers/AccountProvider';
import { RequireAnonymourProps } from './interface';

function RequiresAnonymous({ children }: RequireAnonymourProps) {
    const { account, fetchAccount } = useAccount();
    const navigate = useNavigate();

    const cookie = getCookie('accessToken');

    useEffect(() => {
        if (cookie && !account) {
            fetchAccount!(success => {
                if (!success) eraseCookie('accessToken');
            });
        } else if (account) navigate('/predict');
    }, [account]);

    if ((cookie && !account) || account) return null;

    return children;
}

export default RequiresAnonymous;
