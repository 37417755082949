import { useMemo } from 'react';
import { WalletDTO } from 'DTO/walletDTO';
import { useSettings } from 'providers/SettingProvider';

export default function useFilteredWallets(wallets: WalletDTO[]): WalletDTO[] {
    const {
        settings: { BlockedTokens, MinimumAmount, Chains },
    } = useSettings();
    return useMemo(
        () =>
            wallets.map(wallet => {
                const tokens = wallet.Token.filter(el =>
                    Chains.includes(el.chain),
                )
                    .filter(el => !BlockedTokens.includes(el.id))
                    .filter(el => MinimumAmount <= el.price * el.amount);
                //         .filter(el => !blockedTokens.includes(el.id))

                return {
                    AccountId: wallet.AccountId,
                    CreationDate: wallet.CreationDate,
                    Id: wallet.Id,
                    Token: tokens,
                    Username: wallet.Username,
                    Wallet: wallet.Wallet,
                    Personal: wallet.Personal,
                    Balance: tokens.reduce((a, b) => a + b.price * b.amount, 0),
                };
            }),
        [BlockedTokens, MinimumAmount, Chains, wallets],
    );
}
