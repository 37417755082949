import React, { Suspense, useEffect } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { IRoute, Routes as AppRoutes } from 'routes';
import ReactGA from 'react-ga4';
import FullPage from 'containers/FullPage';
import NotificationsPrompt from 'containers/NotificationsPrompt';
import Loading from 'components/Loading';
import 'antd/dist/reset.css';
import '@fontsource/inter';
import configureStartup from 'startup';
import AccountProvider from 'providers/AccountProvider';
import WalletProvider from 'providers/WalletProvider';
import ScanProvider from 'providers/ScanProvider';
import SettingsProvider from 'providers/SettingProvider';
import { getCookie } from 'utils/cookies';

configureStartup();

if (process.env.NODE_ENV === 'production') ReactGA.initialize('G-1G0J04CLLP');

function App() {
    // unregister notification service worker
    useEffect(() => {
        if (localStorage.getItem('no-notifications-prompt')) return;
        if (getCookie('no-notification-prompt')) return;

        navigator.serviceWorker
            .getRegistrations()
            .then((registrations: any) => {
                // eslint-disable-next-line no-restricted-syntax
                for (const registration of registrations) {
                    registration.unregister();
                }
            });
    }, []);

    return (
        <Suspense fallback={<Loading />}>
            <BrowserRouter>
                <AccountProvider>
                    <WalletProvider>
                        <SettingsProvider>
                            <ScanProvider>
                                <FullPage>
                                    <NotificationsPrompt />
                                    <Routes>
                                        {AppRoutes.map(
                                            ({ url, component }: IRoute) => (
                                                <Route
                                                    key={url}
                                                    path={url}
                                                    element={component}
                                                />
                                            ),
                                        )}
                                    </Routes>
                                </FullPage>
                            </ScanProvider>
                        </SettingsProvider>
                    </WalletProvider>
                </AccountProvider>
            </BrowserRouter>
        </Suspense>
    );
}

export default App;
