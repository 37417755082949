export const defaultChains = ['eth', 'bsc', 'arb', 'base'];

export const chains = [
    { id: 'eth', name: 'Ethereum' },
    { id: 'bsc', name: 'Binance Smart Chain' },
    { id: 'arb', name: 'Arbitrum' },
    { id: 'base', name: 'Base' },
    { id: 'matic', name: 'Polygon' },
    { id: 'ftm', name: 'Fantom' },
    { id: 'cro', name: 'Cronos' },
    { id: 'sol', name: 'Solana' },
];

export const EXPLORER_ENDPOINT = {
    eth: 'https://etherscan.io/',
    bsc: 'https://bscscan.com/',
    arb: 'https://arbiscan.io/',
    base: 'https://basescan.org/',
    matic: 'https://polygonscan.com/',
    ftm: 'https://ftmscan.com/',
    cro: 'https://cronoscan.com/',
    sol: 'https://solscan.io/',
};

export const nativeTokens = [
    'eth',
    'So11111111111111111111111111111111111111112',
    'bsc',
    'arb',
    'base',
    'matic',
    'ftm',
    'cro',
];
