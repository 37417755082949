import React, { useState } from 'react';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { Row } from '@tanstack/react-table';
import { notification } from 'antd';
import { axios } from 'startup';
import { useWallet } from 'providers/WalletProvider';
import { useAccount } from 'providers/AccountProvider';
import { useScan } from 'providers/ScanProvider';
import EditWalletModal from 'components/EditWalletModal';

import { Button } from '../Button';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuShortcut,
    DropdownMenuTrigger,
} from '../DropDown-Menu';

import { taskSchema } from './schema';

interface DataTableRowActionsProps<TData> {
    row: Row<TData>;
}

export function DataTableRowActions<TData>({
    row,
}: DataTableRowActionsProps<TData>) {
    const { fetchWallet } = useWallet();
    const { account } = useAccount();
    const { setSelectedAddress } = useScan();
    const task = taskSchema.parse(row.original);

    const [openModal, setOpenModal] = useState(false);

    const deleteWallet = async () => {
        const res = await axios.post('/accounts/removeWallet', { id: task.Id });
        if (res.data?.ok === true) {
            notification.success({
                message: 'Wallet deleted successfully',
            });
        } else {
            notification.error({
                message: 'Error',
                description: 'Failed to delete wallet',
            });
        }
        fetchWallet(account.account.AccountId);
    };

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button
                    variant='ghost'
                    className='data-table-action-trigger-button'
                >
                    <DotsHorizontalIcon className='data-table-action-trigger-button__icon' />
                    <span className='data-table-action-trigger-button__label'>
                        Open menu
                    </span>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
                align='end'
                className='data-table-action-dropdown-content'
            >
                <DropdownMenuItem
                    onClick={() => {
                        setSelectedAddress(task.Wallet);
                    }}
                >
                    Scan
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => setOpenModal(true)}>
                    Edit Name
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={deleteWallet}>
                    Delete
                    <DropdownMenuShortcut>⌘⌫</DropdownMenuShortcut>
                </DropdownMenuItem>
            </DropdownMenuContent>

            <EditWalletModal
                id={task.Id.toString()}
                walletName={task.Username}
                open={openModal}
                setOpen={setOpenModal}
            />
        </DropdownMenu>
    );
}
