import React, { useEffect } from 'react';
/* eslint-disable react/no-array-index-key */
import Footer from 'components/Footer';
import { motion } from 'framer-motion';
import useWindowSize from 'hooks/useWindowSize';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { fadeFromBottomMotion } from 'utils/animations';
import {
    bannerMotion,
    exploreMotion,
    featuresMotion,
    howItWorksMotion,
    tokenMobileMotion,
    tokenMotion,
} from './animations';
import Feature from './components/Feature';
import HomepageButton from './components/HomepageButton';
import './styles.scss';
import MyWallets from './components/MyWallets';
import StalkedWallets from './components/StalkedWallets';
import WalletScan from './components/WalletScan';

const HomepageButtonMotion = motion(HomepageButton);

function HomePage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const windowSize = useWindowSize();
    const [bannerTextExtended, setBannerTextExtended] = React.useState(false);
    const [exploreTextExtended, setExploreTextExtended] = React.useState(false);

    const resolvedTokenMotion = React.useMemo(() => {
        const isMobile = windowSize.width && windowSize.width! < 600;
        if (isMobile) return tokenMobileMotion;

        return tokenMotion;
    }, [windowSize.width]);

    const toggleBannerText = React.useCallback(() => {
        setBannerTextExtended(show => !show);
    }, []);

    const toggleExploreText = React.useCallback(() => {
        setExploreTextExtended(show => !show);
    }, []);

    return (
        <div className='home-page'>
            <div className='home-page__scan'>
                <div className='home-page__wallets'>
                    <MyWallets />
                    <StalkedWallets />
                </div>
                <WalletScan />
            </div>
            <Footer />
        </div>
    );
}

export default HomePage;
