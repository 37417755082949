import React, { useContext, useEffect, useMemo, useState } from 'react';
import { WalletSummaryDTO } from 'DTO/walletDTO';
import { getWalletInfo } from 'utils/lib/getWalletInfo';
import { axios } from '../startup';

interface IWalletProviderProps {
    children: JSX.Element;
}

interface IWalletContext {
    wallet: WalletSummaryDTO;
    setWallet?: React.Dispatch<React.SetStateAction<WalletSummaryDTO>>;
    setFetchTry?: React.Dispatch<React.SetStateAction<boolean>>;
    fetchWallet?: (accountId: string) => void;
    fetchTry?: boolean;
}

const WalletContext = React.createContext<IWalletContext>({
    wallet: {
        myWallet: [],
        stalkedWallet: [],
    },
});

export const useWallet = () => useContext(WalletContext);

function WalletProvider({ children }: IWalletProviderProps) {
    const [wallet, setWallet] = useState<WalletSummaryDTO>({
        myWallet: [],
        stalkedWallet: [],
    });
    const [fetchTry, setFetchTry] = useState<boolean>(false);

    const value = useMemo<IWalletContext>(
        () => ({
            wallet,
            setWallet,
            setFetchTry,
            fetchWallet: accountId => {
                if (accountId) {
                    axios
                        .get('/accounts/getMyWallets', {
                            params: {
                                accountId,
                            },
                        })
                        .then((res: any) => {
                            getWalletInfo(res.data?.wallets || []).then(
                                myWallet => {
                                    axios
                                        .get('/accounts/stalkedWallets', {
                                            params: {
                                                accountId,
                                            },
                                        })
                                        .then((res1: any) => {
                                            getWalletInfo(
                                                res1.data?.wallets || [],
                                            ).then(stalkedWallet => {
                                                setWallet({
                                                    myWallet,
                                                    stalkedWallet,
                                                });
                                            });
                                        });
                                },
                            );
                        })
                        .finally(() => setFetchTry(true));
                }
            },
            fetchTry,
        }),
        [wallet, fetchTry],
    );

    return (
        <WalletContext.Provider value={value}>
            {children}
        </WalletContext.Provider>
    );
}

export default WalletProvider;
