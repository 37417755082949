import './styles.scss';
import { useTranslation } from 'react-i18next';
import React from 'react';
import Logo from '../../../../assets/images/tokenomics.png';
import { Props } from './interface';

const translation = {
    problems: [
        { LI: 'whitepaper.PROBLEMS_LI1' },
        { LI: 'whitepaper.PROBLEMS_LI2' },
    ],
    solutions: [
        { LI: 'whitepaper.SOLUTIONS_LI1' },
        { LI: 'whitepaper.SOLUTIONS_LI2' },
    ],
    marketop: {
        PLIST: [
            { P: 'whitepaper.MARKETOPORTUNITY_P1' },
            { P: 'whitepaper.MARKETOPORTUNITY_P2' },
        ],
        LIST: [
            { LI: 'whitepaper.MARKETOPORTUNITY_LI1' },
            { LI: 'whitepaper.MARKETOPORTUNITY_LI2' },
            { LI: 'whitepaper.MARKETOPORTUNITY_LI3' },
            { LI: 'whitepaper.MARKETOPORTUNITY_LI4' },
        ],
        PLIST2: [
            { P: 'whitepaper.MARKETOPORTUNITY_P3' },
            { P: 'whitepaper.MARKETOPORTUNITY_P4' },
            { P: 'whitepaper.MARKETOPORTUNITY_P5' },
            { P: 'whitepaper.MARKETOPORTUNITY_P6' },
        ],
    },
    howOGWorks: {
        P1: 'whitepaper.HOWOGWORKS_P1',
        LIST: [
            { LI: 'whitepaper.HOWOGWORKS_LI1' },
            { LI: 'whitepaper.HOWOGWORKS_LI2' },
            { LI: 'whitepaper.HOWOGWORKS_LI3' },
            { LI: 'whitepaper.HOWOGWORKS_LI4' },
            { LI: 'whitepaper.HOWOGWORKS_LI5' },
            { LI: 'whitepaper.HOWOGWORKS_LI6' },
        ],
        P2: 'whitepaper.HOWOGWORKS_P2',
    },
    upcomingFeature: [
        { P: 'whitepaper.UPCOMINGFEATURE_P1' },
        { P: 'whitepaper.UPCOMINGFEATURE_P2' },
        { P: 'whitepaper.UPCOMINGFEATURE_P3' },
        { P: 'whitepaper.UPCOMINGFEATURE_P4' },
        { P: 'whitepaper.UPCOMINGFEATURE_P5' },
    ],
    tokenomics: [
        { P: 'whitepaper.TOKENOMICS_P1' },
        { P: 'whitepaper.TOKENOMICS_P2' },
        { P: 'whitepaper.TOKENOMICS_P3' },
        { P: 'whitepaper.TOKENOMICS_P4' },
        { P: 'whitepaper.TOKENOMICS_P5' },
        { P: 'whitepaper.TOKENOMICS_P6' },
        { P: 'whitepaper.TOKENOMICS_P7' },
        { P: 'whitepaper.TOKENOMICS_P8' },
    ],
    teamBackgroud: [
        { P: 'whitepaper.TEAMBACKGROUD_P1' },
        { P: 'whitepaper.TEAMBACKGROUD_P2' },
        { P: 'whitepaper.TEAMBACKGROUD_P3' },
        { P: 'whitepaper.TEAMBACKGROUD_P4' },
    ],
    connect: [
        {
            link: {
                P: 'whitepaper.CONNECT_P1',
                URL: 'https://ogpredict.com',
            },
        },
        {
            link: {
                P: 'whitepaper.CONNECT_P2',
                URL: 'https://t.me/ogpredict',
            },
        },
        {
            link: {
                P: 'whitepaper.CONNECT_P3',
                URL: 'https://t.me/ogpredict_ann',
            },
        },
        {
            link: {
                P: 'whitepaper.CONNECT_P4',
                URL: 'https://twitter.com/og_predict',
            },
        },
        {
            link: {
                P: 'whitepaper.CONNECT_P5',
                URL: 'https://ogpredict.medium.com',
            },
        },
        {
            link: {
                P: 'whitepaper.CONNECT_P6',
                URL: 'https://www.youtube.com/channel/UCvpwQGsSL7vjRwHzbtbA5Vg',
            },
        },
    ],
};

function MainContent({ showDrawer }: Props) {
    const { t } = useTranslation();

    // const funct = (header: string) => {
    //     const array = Object.entries(translations).filter(a => {
    //         return a[0].includes(header);
    //     });

    //     const object: { [x: string]: string } = array.map(e => ({
    //         [e[0]]: e[1],
    //     }));
    //     return array;
    // };

    return (
        <div className={showDrawer ? 'content' : 'content-nodrawer'}>
            <h1 className='content__h1'>{t('whitepaper.HEADER1')}</h1>
            <h2 className='content__h2' id='initial-header'>
                {t('whitepaper.HEADER2')}
            </h2>
            <p>{t('whitepaper.INTRODUCTION_P1')}</p>
            <h2 id='second-header'>{t('whitepaper.HEADER3')}</h2>
            {translation.problems.map((e: { LI: string }) => {
                return (
                    <ul key={e.LI}>
                        <li>{t(e.LI)}</li>
                    </ul>
                );
            })}
            <h2 id='third-header'>{t('whitepaper.HEADER4')}</h2>
            {translation.solutions.map((e: { LI: string }) => {
                return (
                    <ul key={e.LI}>
                        <li>{t(e.LI)}</li>
                    </ul>
                );
            })}
            <h2 id='fourth-header'>{t('whitepaper.HEADER5')}</h2>
            {translation.marketop.PLIST.map(e => {
                return <p key={e.P}>{t(e.P)}</p>;
            })}
            {translation.marketop.LIST.map(e => {
                return (
                    <ul key={e.LI}>
                        <li>{t(e.LI)}</li>
                    </ul>
                );
            })}
            {translation.marketop.PLIST2.map(e => {
                return <p key={e.P}>{t(e.P)}</p>;
            })}
            <h2 id='fifth-header'>{t('whitepaper.HEADER6')}</h2>

            <p>{t(translation.howOGWorks.P1)}</p>
            {translation.howOGWorks.LIST.map(e => {
                return (
                    <ul key={e.LI}>
                        <li>{t(e.LI)}</li>
                    </ul>
                );
            })}
            <p>{t(translation.howOGWorks.P2)}</p>
            <h2 id='sixth-header'>{t('whitepaper.HEADER7')}</h2>
            {translation.upcomingFeature.map((e: { P: string }) => {
                return <p key={e.P}>{t(e.P)}</p>;
            })}
            <h2 id='seventh-header'>{t('whitepaper.HEADER8')}</h2>
            {translation.tokenomics.map((e: { P: string }) => {
                return <p key={e.P}>{t(e.P)}</p>;
            })}
            <img className='content__img' src={Logo} alt='/' />
            <h2 id='find-highlight'>{t('whitepaper.HEADER9')}</h2>
            {translation.teamBackgroud.map((e: { P: string }) => {
                return <p key={e.P}>{t(e.P)}</p>;
            })}
            <h2 id='conclusion'>{t('whitepaper.HEADER10')}</h2>
            {translation.connect.map(e => {
                return (
                    <div key={e.link.P} className='content__urls'>
                        <p>{t(e.link.P)}</p>
                        <a href={e.link.URL} target='_blank' rel='noreferrer'>
                            {e.link.URL}
                        </a>
                    </div>
                );
            })}
        </div>
    );
}

export default MainContent;
