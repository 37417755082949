import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as NotFoundIcon } from 'assets/icons/not_found_404.svg';
import { ReactComponent as SoccerBallIcon } from 'assets/icons/soccer_ball.svg';
import { motion } from 'framer-motion';
import './styles.scss';

function NotFoundPage() {
    const { t } = useTranslation();

    return (
        <div className='not-found-page'>
            <NotFoundIcon className='not-found-page__background' />
            <div className='not-found-page__content'>
                <motion.p
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                        duration: 0.4,
                        delay: 0.4,
                        type: 'spring',
                    }}
                    initial={{ opacity: 0, scale: 0.3 }}
                    className='not-found-page__content__header'
                >
                    4
                    <SoccerBallIcon className='not-found-page__content__header__icon' />
                    4
                </motion.p>
                <motion.p
                    animate={{ opacity: 1 }}
                    transition={{
                        duration: 0.4,
                        delay: 0.6,
                        ease: 'easeInOut',
                    }}
                    initial={{ opacity: 0 }}
                    className='not-found-page__content__text'
                >
                    {t('notFound.SORRY')}
                </motion.p>
                <motion.p
                    animate={{ opacity: 1 }}
                    transition={{
                        duration: 0.4,
                        delay: 0.8,
                        ease: 'easeInOut',
                    }}
                    initial={{ opacity: 0 }}
                    className='not-found-page__content__text'
                >
                    {t('notFound.TAKE_ME_BACK')}&nbsp;
                    <Link
                        to='/'
                        className='not-found-page__content__text__link'
                    >
                        https://dev-app.trackerai.bot/
                    </Link>
                </motion.p>
            </div>
        </div>
    );
}

export default NotFoundPage;
