import React from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { WalletDTO } from 'DTO/walletDTO';
import { DataTable } from 'components/DataTable';
import { useWallet } from 'providers/WalletProvider';
import { useSettings } from 'providers/SettingProvider';
import useFilteredWallets from 'hooks/useFilteredWallets';
import { columns } from './columns';

function StalkedWallets() {
    const { wallet, fetchWallet } = useWallet();
    const wallets = useFilteredWallets(wallet.stalkedWallet);

    return (
        <div className='mywallets'>
            <div className='mywallets__wrapper'>
                <div className='mywallets__wrapper__header'>
                    <h2 className='mywallets__wrapper__header__title'>
                        Stalked Wallets
                    </h2>
                </div>
                <DataTable
                    data={wallets.map((el: WalletDTO) => {
                        return {
                            Id: el.Id,
                            Username: el.Username,
                            Wallet: el.Wallet,
                            Balance: el.Balance,
                        };
                    })}
                    columns={columns}
                />
            </div>
        </div>
        // <motion.div className={classNames('feature', className)} {...rest}>
        //     <div className='feature__blur' />
        //     <div className='feature__image-wrapper' />
        //     {image}
        //     <div className='feature__info'>
        //         <div className='feature__info__pre-title'>{preTitle}</div>
        //         <div className='feature__info__title'>{title}</div>
        //         <div className='feature__info__text'>{text}</div>
        //     </div>
        // </motion.div>
    );
}

export default StalkedWallets;
