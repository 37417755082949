/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import logo from 'assets/images/logo.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CloseIcon from 'assets/icons/close_btn.svg';
import MenuProfile from 'components/MenuProfile';
import AvatarSample from 'assets/icons/avatar-white.svg';
import PremiumBadge from 'assets/images/premium.png';
// import TelegramLoginWidget from 'components/TelegramLoginWidget';
import './styles.scss';
import appConfig from 'config';
import { Button } from 'components/Button';
import classNames from 'classnames';
import { useScroll, motion } from 'framer-motion';
import { useAccount } from 'providers/AccountProvider';
import { eraseCookie } from 'utils/cookies';
import { axios } from 'startup';
import { notification } from 'antd';
import TelegramLoginWidget2 from 'components/TelegramLoginWidget2';
import { menuMotion } from './animations';

function Menu() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [premium, setPremium] = useState(false);
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { scrollY } = useScroll();
    const menuRef = useRef<HTMLDivElement>(null);
    const { account, setAccount } = useAccount();
    const navigate = useNavigate();

    const items = [
        // {
        //     url: '/scan',
        //     text: 'general.SCAN',
        // },
        // {
        //     url: '/whitepaper',
        //     text: 'whitepaper.TITLE',
        // },
    ];
    if (account) {
        items.push(
            {
                url: '/scan',
                text: 'general.SCAN',
            },
            {
                url: '/portfolio',
                text: 'general.PORTFOLIO',
            },
            {
                url: '/pnl',
                text: 'general.PNL',
            },
            {
                url: '/settings',
                text: 'general.SETTINGS',
            },
        );
    }

    useEffect(() => {
        return scrollY.onChange(latest => {
            if (latest === 0) {
                menuRef.current!.classList.remove('menu--scrolled');
            } else menuRef.current!.classList.add('menu--scrolled');
        });
    }, []);

    useEffect(() => {
        if (account) {
            const checkPremium = async () => {
                const res = await axios.get('/accounts/checkIfPremium', {
                    params: {
                        accountId: account.account.AccountId,
                    },
                });

                if (res.data?.premiumIf?.premium) {
                    setPremium(true);
                }
            };

            checkPremium();
        }
    }, [account]);

    const avatarSrc =
        account && account.account.avatar
            ? `${appConfig.API_URL}/${account.account.avatar}`
            : AvatarSample;

    return (
        <motion.div
            animate='animated'
            ref={menuRef}
            className={!menuOpen ? 'menu' : 'menu-mobile'}
        >
            <div
                className={classNames('menu-mobile__container', {
                    'menu-mobile__container--mobile': menuOpen,
                    'menu-mobile__container--account': account,
                })}
            >
                {account && menuOpen && (
                    <div className='menu-mobile__profile'>
                        <Link className='menu-mobile__profile__link' to='/'>
                            <img
                                className='menu-mobile__profile__avatar'
                                src={avatarSrc}
                                alt='Avatar Img'
                            />
                            {premium && (
                                <img
                                    className='menu-mobile__profile__premium'
                                    src={PremiumBadge}
                                    alt='Premium Img'
                                />
                            )}
                        </Link>
                        <div className='menu-mobile__profile__info'>
                            <Link to='/profile'>
                                {account?.account.Username}
                            </Link>
                        </div>
                    </div>
                )}

                {!menuOpen && (
                    <div className='menu-mobile__hamburger'>
                        <button
                            className='menu-mobile__hamburger__button'
                            type='button'
                            onClick={() => {
                                setMenuOpen(true);
                            }}
                        >
                            <div />
                            <div />
                            <div />
                        </button>
                    </div>
                )}
                {!menuOpen && (
                    <div>
                        <Link to='/'>
                            <motion.img
                                initial='rest'
                                animate='animated'
                                variants={menuMotion.logo}
                                className='menu__logo'
                                src={logo}
                                alt=''
                            />
                        </Link>
                    </div>
                )}

                <motion.div
                    animate='animated'
                    variants={menuMotion.items}
                    className={
                        menuOpen && account
                            ? 'menu-mobile__items'
                            : menuOpen && !account
                            ? 'menu-mobile__items-noaccount'
                            : 'menu__items'
                    }
                >
                    {items.map(({ url, text }) => (
                        <Link
                            key={url}
                            className={classNames('menu__items__item', {
                                'menu__items__item--active': pathname === url,
                            })}
                            to={url}
                        >
                            {t(text)}
                        </Link>
                    ))}
                    {account?.account.isAdmin && (
                        <Link
                            className={classNames('menu__items__item', {
                                'menu__items__item--active':
                                    pathname === '/admin/fixtures',
                            })}
                            to='/admin/fixtures'
                        >
                            Admin
                        </Link>
                    )}

                    {!account && menuOpen && (
                        <div className='menu-mobile__signup-login'>
                            <TelegramLoginWidget2 />
                        </div>
                    )}

                    {menuOpen && (
                        <button
                            type='button'
                            onClick={() => {
                                setMenuOpen(false);
                            }}
                            className='menu__mobile-close-btn'
                        >
                            <img src={CloseIcon} alt='X' />
                        </button>
                    )}
                </motion.div>
                {account && menuOpen && (
                    <div className='menu-mobile__logout-btn-container'>
                        <Button
                            onClick={() => {
                                eraseCookie('accessToken');
                                if (setAccount) setAccount(undefined);
                                navigate('/');
                            }}
                            className='menu-mobile__logout-btn'
                        >
                            {t('general.LOGOUT')}
                        </Button>
                    </div>
                )}
                {!menuOpen && (
                    <motion.div
                        initial='rest'
                        animate='animated'
                        variants={menuMotion.profile}
                        className='menu__menu-profile-container'
                    >
                        <MenuProfile />
                    </motion.div>
                )}
            </div>
        </motion.div>
    );
}

export default Menu;
