import React from 'react';
import { useSettings } from 'providers/SettingProvider';
import { Checkbox } from 'components/Checkbox';
import { chains } from 'chains';

interface ChainSettingProps {
    selectedChains: string[];
    setSelectedChains: (value: string[]) => void;
}

function ChainSetting({
    selectedChains,
    setSelectedChains,
}: ChainSettingProps) {
    const handleCheck = (value: boolean | string, id: string) => {
        if (value === true) {
            setSelectedChains([...selectedChains, id]);
        }
        if (value === false) {
            setSelectedChains(selectedChains.filter(c => c !== id));
        }
    };

    return (
        <>
            <div className='settings__wrapper__content__main__header'>
                <div className='settings__wrapper__content__main__header__title'>
                    Chain Setting
                </div>
                <div className='settings__wrapper__content__main__header__description'>
                    Select the chains you want to be displayed when you scan
                </div>
            </div>

            <div className='settings__wrapper__content__main__chains'>
                {chains.map(chain => (
                    <div
                        key={chain.id}
                        className='settings__wrapper__content__main__chains__chain'
                    >
                        <Checkbox
                            checked={selectedChains.includes(chain.id)}
                            onCheckedChange={value =>
                                handleCheck(value, chain.id)
                            }
                        />
                        <p className='settings__wrapper__content__main__chains__chain__name'>
                            {chain.name}
                        </p>
                    </div>
                ))}
            </div>
        </>
    );
}

export default ChainSetting;
