import { axios } from 'startup';

const publicVapidKey =
    'BP_aFi1VhJx1D9lrzHMVk5jaD-iuIgbGSG8cZvedsyaimoJrbiojvbubN1DaTQbwGav4eX2Wr4bfkQgr-y5pcDs';

function urlBase64ToUint8Array(base64String: string) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; i += 1) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export async function getSubscription() {
    const registration =
        await navigator.serviceWorker.getRegistration('/sw.js');
    const subscription = await registration?.pushManager.getSubscription();
    return subscription?.endpoint;
}

export async function subscribe() {
    navigator.serviceWorker.register('/sw.js', {
        scope: '/',
    });

    navigator.serviceWorker.ready.then(async serviceWorkerRegistration => {
        const subscription =
            await serviceWorkerRegistration.pushManager.subscribe({
                userVisibleOnly: true,

                applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
            });

        await axios.post('/notifications/subscribe', {
            body: subscription,
            headers: {
                'content-type': 'application/json',
            },
        });
    });
}
