import React, { useEffect } from 'react';
import { Input } from 'components/Input';
import { Checkbox } from 'components/Checkbox';
import { useWallet } from 'providers/WalletProvider';
import { chains } from 'chains';

interface TokenSettingProps {
    minimumAmount: string;
    setMinimumAmount: (value: string) => void;
    blockedTokens: string[];
    setBlockedTokens: (value: string[]) => void;
}

function TokenSetting({
    minimumAmount,
    setMinimumAmount,
    blockedTokens,
    setBlockedTokens,
}: TokenSettingProps) {
    const { wallet } = useWallet();

    const [tokens, setTokens] = React.useState({});

    const handleCheck = (value: boolean | string, id: string) => {
        if (value === true) {
            setBlockedTokens(blockedTokens.filter(c => c !== id));
        }
        if (value === false) {
            setBlockedTokens([...blockedTokens, id]);
        }
    };

    useEffect(() => {
        if (wallet) {
            const myWalletTokens = wallet.myWallet.flatMap(one =>
                one.Token.map(token => {
                    return {
                        id: token.id,
                        chain: token.chain,
                        name: token.name,
                        logoUrl: token.logo_url,
                    };
                }),
            );

            const stalkedWalletTokens = wallet.stalkedWallet.flatMap(one =>
                one.Token.map(token => {
                    return {
                        id: token.id,
                        chain: token.chain,
                        name: token.name,
                        logoUrl: token.logo_url,
                    };
                }),
            );

            const allTokens = [...myWalletTokens, ...stalkedWalletTokens];

            const tokensData = {};
            // eslint-disable-next-line array-callback-return
            allTokens.map(token => {
                /* eslint-disable no-prototype-builtins */
                if (!tokensData.hasOwnProperty(token.chain)) {
                    tokensData[token.chain] = [];
                }

                if (
                    !tokensData[token.chain].find(item => item.id === token.id)
                ) {
                    tokensData[token.chain].push({
                        id: token.id,
                        name: token.name,
                        logoUrl: token.logoUrl,
                    });
                }
            });

            setTokens(tokensData);
        }
    }, [wallet]);

    return (
        <>
            <div className='settings__wrapper__content__main__header'>
                <div className='settings__wrapper__content__main__header__title'>
                    Token Setting
                </div>
            </div>

            <div className='settings__wrapper__content__main__tokens'>
                <div className='settings__wrapper__content__main__tokens__minimum'>
                    <div className='settings__wrapper__content__main__tokens__minimum__label'>
                        Minimum amount (in USD) you wish to be displayed
                    </div>
                    <Input
                        className='settings__wrapper__content__main__tokens__minimum__input'
                        type='number'
                        value={minimumAmount}
                        onChange={e => setMinimumAmount(e.target.value)}
                        step='any'
                    />
                </div>

                <div className='settings__wrapper__content__main__tokens__tokens'>
                    <div className='settings__wrapper__content__main__tokens__tokens__label'>
                        Select the tokens you want to see in your portfolio
                    </div>
                    <div className='settings__wrapper__content__main__tokens__tokens__list'>
                        {Object.keys(tokens).map(chain => {
                            const selectedChain = chains.find(
                                one => one.id === chain,
                            );

                            if (selectedChain) {
                                return (
                                    <div
                                        key={chain}
                                        className='settings__wrapper__content__main__tokens__tokens__item'
                                    >
                                        <div className='settings__wrapper__content__main__tokens__tokens__chain'>
                                            {selectedChain.name}
                                        </div>
                                        <div className='settings__wrapper__content__main__tokens__tokens__tokens'>
                                            {tokens[selectedChain.id].map(
                                                token => (
                                                    <div
                                                        key={token.id}
                                                        className='settings__wrapper__content__main__tokens__tokens__token'
                                                    >
                                                        <Checkbox
                                                            checked={
                                                                !blockedTokens.includes(
                                                                    token.id,
                                                                )
                                                            }
                                                            onCheckedChange={value =>
                                                                handleCheck(
                                                                    value,
                                                                    token.id,
                                                                )
                                                            }
                                                        />
                                                        <img
                                                            className='settings__wrapper__content__main__tokens__tokens__token__logo'
                                                            src={token.logoUrl}
                                                            alt='token'
                                                            width={32}
                                                            height={32}
                                                        />
                                                        <p className='settings__wrapper__content__main__tokens__tokens__token__name'>
                                                            {token.name}
                                                        </p>
                                                    </div>
                                                ),
                                            )}
                                        </div>
                                    </div>
                                );
                            }

                            return null;
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}

export default TokenSetting;
