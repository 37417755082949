import { useEffect, useState } from 'react';
import axios from 'axios';
import { convertDateToHour, convertDateToShort } from 'utils/date';
import {
    analyzeRate,
    calculateRate,
    fetchToken,
    formatCost,
    getPreviousPrice,
    verifyAddress,
} from 'utils/debank';
import appConfig from 'config';
import { sleep } from 'utils/delay';
import { useSettings } from 'providers/SettingProvider';

export const usePnlData = (walletAddress: string, dateRange: string) => {
    const { settings } = useSettings();
    const [walletTokens, setWalletTokens] = useState<any[]>([]);
    const [profit, setProfit] = useState(0);
    const [profitRate, setProfitRate] = useState('0');
    const [profitHistory, setProfitHistory] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const getPnlData = async () => {
        setLoading(true);

        const data = await fetchToken(walletAddress);
        const tokens = data.data
            .filter((one: any) => one.price !== 0)
            .filter(el => settings.Chains.includes(el.chain))
            .filter(el => !settings.BlockedTokens.includes(el.id))
            .filter(el => el.amount * el.price >= settings.MinimumAmount);
        const totalBalance = tokens.reduce(
            (a: any, b: any) => a + b.amount * b.price,
            0,
        );

        const endDate = Date.now();
        let fromDate = endDate;

        if (dateRange === '24H') {
            fromDate -= 24 * 60 * 60 * 1000;
        }

        if (dateRange === '7D') {
            fromDate -= 7 * 24 * 60 * 60 * 1000;
        }
        if (dateRange === '30D') {
            fromDate -= 30 * 24 * 60 * 60 * 1000;
        }

        let previousTotal = 0;
        const analyzeTokens = [];
        const profitChartData = [];
        const profitData: any = {};

        await Promise.all(
            tokens.map(async token => {
                const previousPrice = await getPreviousPrice(
                    [token.chain],
                    new Date(fromDate).toLocaleDateString('en-CA'),
                    [token],
                );
                previousTotal += token.amount * previousPrice[0].price;
                const rate = calculateRate(token.price, previousPrice[0].price);
                analyzeTokens.push({
                    id: token.id,
                    name: token.name,
                    chain: token.chain,
                    logo_url: token.logo_url,
                    amount: `$${formatCost(token.amount * token.price)}`,
                    rate: analyzeRate(rate),
                });
            }),
        );
        analyzeTokens.sort((a, b) => b.rate - a.rate);

        if (dateRange === '24H') {
            const url = `https://pro-openapi.debank.com/v1/user/total_net_curve?id=${walletAddress}`;
            const headers = {
                AccessKey: appConfig.DEBANK_API_KEY,
            };

            const response = await axios.get(url, {
                headers,
            });
            const histories = response.data;

            for (let index = 7; index < histories.length - 1; index += 28) {
                const history = histories[index];

                const date = new Date(Number(history.timestamp) * 1000);
                const profitValue = parseFloat(history.usd_value);

                profitChartData.push({
                    date: convertDateToHour(date),
                    value: profitValue - parseFloat(histories[0].usd_value),
                });
            }

            profitChartData.push({
                date: convertDateToHour(new Date(endDate)),
                value: totalBalance - previousTotal,
            });
        } else {
            for (
                let date = fromDate;
                date < endDate;
                date +=
                    dateRange === '7D'
                        ? 24 * 60 * 60 * 1000
                        : 4 * 24 * 60 * 60 * 1000
            ) {
                // eslint-disable-next-line no-await-in-loop
                await Promise.all(
                    tokens.map(async token => {
                        const previousPrice = await getPreviousPrice(
                            [token.chain],
                            new Date(date).toLocaleDateString('en-CA'),
                            [token],
                        );
                        profitData[convertDateToShort(new Date(date))] =
                            (profitData[convertDateToShort(new Date(date))] ||
                                0) +
                            (previousPrice[0].price * token.amount || 0);
                    }),
                );

                // eslint-disable-next-line no-await-in-loop
                await sleep(100);
            }

            const firstValue = profitData[Object.keys(profitData)[0]];

            const keys = Object.keys(profitData);
            const unknownKeys = keys.filter(key => profitData[key] === 0);

            Object.keys(profitData).forEach(key => {
                if (!unknownKeys.includes(key))
                    profitChartData.push({
                        date: key,
                        value: profitData[key] - firstValue,
                    });
            });

            profitChartData.push({
                date: convertDateToShort(new Date(endDate)),
                value: totalBalance - previousTotal,
            });
        }

        setProfit(totalBalance - previousTotal);
        setProfitRate(calculateRate(totalBalance, previousTotal));
        setWalletTokens(analyzeTokens);
        setProfitHistory(profitChartData);
        setLoading(false);
    };

    useEffect(() => {
        if (walletAddress && verifyAddress(walletAddress)) {
            getPnlData();
        }
    }, [walletAddress, dateRange]);

    return {
        loading,
        profit,
        profitRate,
        walletTokens,
        profitHistory,
    };
};
