import React from 'react';
import { TabsProps } from './interface';

function Tabs({ selected, setSelected }: TabsProps) {
    return (
        <div className='tabs'>
            <div className='tabs__list'>
                <button
                    type='button'
                    className={`tabs__list__item ${
                        selected === '24H' && 'selected'
                    }`}
                    onClick={() => setSelected('24H')}
                >
                    24H
                </button>
                <button
                    type='button'
                    className={`tabs__list__item ${
                        selected === '7D' && 'selected'
                    }`}
                    onClick={() => setSelected('7D')}
                >
                    7D
                </button>
                <button
                    type='button'
                    className={`tabs__list__item ${
                        selected === '30D' && 'selected'
                    }`}
                    onClick={() => setSelected('30D')}
                >
                    30D
                </button>
            </div>
        </div>
    );
}

export default Tabs;
