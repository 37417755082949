import React from 'react';
import { axios } from 'startup';
import { setCookie, eraseCookie } from 'utils/cookies';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'providers/AccountProvider';
import { useSettings } from 'providers/SettingProvider';
import TelegramLoginModal from './TelegramLoginModal';
import './styles.scss';

function TelegramLoginWidget2() {
    const { fetchTry, fetchAccount } = useAccount();
    const { fetchSettings } = useSettings();
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();
    const login = (otp: string) => {
        axios
            .post('/accounts/auth', { otp })
            .then(response => {
                if (response.data.message === 'Successfully authenticated') {
                    // console.log('authenticated');
                    setCookie('accessToken', response.data.token, 30);
                    setOpen(false);

                    fetchAccount!(success => {
                        if (success) {
                            return;
                        }
                        eraseCookie('accessToken');
                    });
                }
            })
            .catch(error => {
                // console.log(error);
            });
    };

    return (
        <div>
            <button
                type='button'
                className='login-button'
                onClick={() => setOpen(!open)}
            >
                {t('login.LOGIN_WITH_TELEGRAM')}
            </button>
            <TelegramLoginModal open={open} setOpen={setOpen} login={login} />
        </div>
    );
}

export default TelegramLoginWidget2;
