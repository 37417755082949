import React from 'react';
import Footer from 'components/Footer';
import './styles.scss';
import Settings from './components/Settings';

function SettingsPage() {
    return (
        <div className='settings-page'>
            <div className='settings-page__settings'>
                <Settings />
            </div>
            <Footer />
        </div>
    );
}

export default SettingsPage;
